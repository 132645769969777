import { InlineNotification } from '@packages/shared';
import { useIntl } from 'react-intl';
import { ErrorFallbackMessages } from './messages';

export type InstallmentCalculatorErrorFallbackProps = {
  onCloseHandler?(): void;
};

export const InstallmentCalculatorErrorFallback = ({
  onCloseHandler,
}: InstallmentCalculatorErrorFallbackProps) => {
  const { formatMessage } = useIntl();
  return (
    <InlineNotification onClose={onCloseHandler} severity="warning">
      {formatMessage(ErrorFallbackMessages.RequestError)}
    </InlineNotification>
  );
};
