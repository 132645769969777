import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgArrowMiniLeft32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="m11.868 16.002 5.05 4.921a.304.304 0 0 0 .425 0l.578-.562a.29.29 0 0 0-.005-.419l-4.043-3.94 4.043-3.941a.289.289 0 0 0 .005-.419l-.578-.563a.303.303 0 0 0-.425 0z" className="arrow-mini-left-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgArrowMiniLeft32);
export default Memo;