import { Box, Typography } from '@packages/shared';
import { ArrowLargeRight32 } from '@packages/themes/icons';
import { fontSwitcher } from '../../utils';
import { SoftHyphenInjector } from '../SoftHyphenInjector';
import type { OverlayRectangleType } from './types';
import { styleHelperFontSize, styleHelperLineHeight } from './utils';

/**
 * This component shows editorial content of a teaser.
 */
export const OverlayRectangleItem = ({ data }: { data: OverlayRectangleType }) => {
  const {
    field_headline: headline,
    field_text_subheadline: subHeadline,
    field_font: styleFont,
    field_font_subheadline: styleFontSubHeadline,
    field_negative_text_color: styleIsNegativeTextColor = false,
    field_size: styleSize = 'big',
    field_size_subheadline: styleSizeSubHeadline = 'small',
  } = data;

  return (
    <Box
      sx={{
        color: styleIsNegativeTextColor ? 'text.light' : 'text.dark',
      }}
      component="span"
    >
      {headline && (
        <Typography
          component="span"
          className={`headline ${fontSwitcher(styleFont)}`}
          sx={{
            display: 'block',
            fontSize: styleHelperFontSize(styleSize),
            lineHeight: styleHelperLineHeight(styleSize),
          }}
        >
          <SoftHyphenInjector text={headline} />
        </Typography>
      )}
      {subHeadline && (
        <Typography
          component="span"
          className={`sub-headline ${fontSwitcher(styleFontSubHeadline)}`}
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
            fontSize: styleHelperFontSize(styleSizeSubHeadline),
            lineHeight: styleHelperLineHeight(styleSizeSubHeadline),
            marginTop: 1,
          }}
        >
          <SoftHyphenInjector text={subHeadline} />
        </Typography>
      )}
      <Box
        sx={(theme) => ({
          display: 'inline-block',
          color: styleIsNegativeTextColor ? 'text.light' : 'text.dark',
          fill: styleIsNegativeTextColor ? 'text.light' : 'text.dark',
          position: 'absolute',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
          '& svg': {
            width: theme.spacing(2.5),
            height: theme.spacing(2.5),
          },
        })}
        component="span"
      >
        <ArrowLargeRight32
          sx={{
            color: styleIsNegativeTextColor ? 'text.light' : 'text.dark',
            fill: styleIsNegativeTextColor ? 'text.light' : 'text.dark',
          }}
        />
      </Box>
    </Box>
  );
};
