import type { PageTemplate } from '@packages/recommendations';
import {
  LastSeenProductsRecoSlider,
  SearchTopsellerProductsRecoSlider,
} from '@packages/recommendations';
import { Box } from '@packages/shared';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';
import type { EpsRecommendationType } from './types';

const Reco = ({
  data,
  pageTemplate,
  elementIndex = 0,
}: {
  data: EpsRecommendationType;
  elementIndex?: number;
  pageTemplate: PageTemplate;
}) => {
  const {
    field_reco_service_eps: recoServiceType,
    field_auto_headline_disabled: disableAutoHeadline,
  } = data;

  switch (recoServiceType) {
    case 'lastseen':
      return (
        <LastSeenProductsRecoSlider
          pageTemplate={pageTemplate}
          elementIndex={elementIndex}
          fetchOnThreshold
          title=""
          {...(disableAutoHeadline && { preferTitle: true })}
        />
      );
    case 'topseller':
      return (
        <SearchTopsellerProductsRecoSlider
          elementIndex={elementIndex}
          pageTemplate={pageTemplate}
          fetchOnThreshold
          title=""
          {...(disableAutoHeadline && { preferTitle: true })}
        />
      );
    default:
      return <span data-testid="cms-reco-no-reco-type-eps-found" />;
  }
};

export const CmsEpsRecommendation = ({
  data,
  recoIndex = 0,
  pageTemplate = 'homepage',
}: {
  data: EpsRecommendationType;
  recoIndex?: number;
  pageTemplate?: PageTemplate;
}) => {
  const { behavior_paragraph_outer_spacing: margin } = data;

  return (
    // use margin from cms. if no marging has been given by cms, set a margin-bottom of 8.
    <Box sx={{ margin, marginBottom: !margin ? 8 : undefined }}>
      <Reco data={data} pageTemplate={pageTemplate} elementIndex={recoIndex} />
    </Box>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.RecoEps]: CmsEpsRecommendation });
