import type { InstallmentCalulatorLayouts } from '../../types';
import { Collapsed } from './Collapsed';
import { CompactAndColumn } from './CompactAndColumn';

export interface InstallmentCalculatorSuspenseFallbackProps {
  calculatorLayout: InstallmentCalulatorLayouts;
}

export const InstallmentCalculatorSuspenseFallback = ({
  calculatorLayout,
}: InstallmentCalculatorSuspenseFallbackProps) =>
  calculatorLayout === 'Collapsible' ? <Collapsed /> : <CompactAndColumn />;
