import { useState } from 'react';
import { LinkButton } from '@packages/shared';
import { InstallmentCalculatorDialog } from '@packages/installment-calculator';
import type { CmsInstallmentCalculatorType } from './types';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';

/**
 * This component shows an installment calculator when clicking on a text link.
 * The user can calculate how he could pay his product in installments.
 *
 * @param data Object contains data
 * @returns JSX.Element to be placed in PageLayout
 */
export const CmsInstallmentCalculator = ({ data }: { data: CmsInstallmentCalculatorType }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const { field_link: linkTitle } = data;

  return (
    <>
      {linkTitle && (
        <LinkButton
          color="inherit"
          onClick={() => {
            setDialogOpened(true);
          }}
        >
          {linkTitle}
        </LinkButton>
      )}
      {dialogOpened && (
        <InstallmentCalculatorDialog
          basePrice={10000}
          open={dialogOpened}
          handleClose={() => setDialogOpened(false)}
          hasEditablePrice
        />
      )}
    </>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.InstallmentCalculator]: CmsInstallmentCalculator });
