import React from 'react';
import { MuiIconButton } from '../../Button';
import { Box } from '../../Box';
import { getDotStyles, getIconButtonStyles } from './styles';

export interface DotProps {
  isSelected: boolean;
  onClickHandler: () => void;
}

export const Dot = ({ isSelected, onClickHandler }: DotProps) => (
  <MuiIconButton sx={getIconButtonStyles(isSelected)} onClick={() => onClickHandler()}>
    <Box sx={getDotStyles(isSelected)} />
  </MuiIconButton>
);
