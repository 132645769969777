import config from '@packages/config';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import { useCustomer } from '@packages/shared/src/hooks/useCustomer/useCustomer';

export const useShopfinderUrl = () => {
  const { customer } = useCustomer();
  const { locale, defaultLocale } = useI18n();
  const url = new URL(config.hermesShopFinder.url);

  url.search = decodeURIComponent(
    new URLSearchParams({
      r: config.hermesShopFinder.rParam,
      ...(customer?.billingAddress?.street && {
        street: customer.billingAddress.street,
      }),
      ...(customer?.billingAddress?.zipCode && {
        zip: customer.billingAddress.zipCode,
      }),
      ...(customer?.billingAddress?.city && {
        city: customer.billingAddress.city,
      }),
      lang: (locale || defaultLocale).split('-')[0],
      countries: (locale || defaultLocale).split('-')[1],
      noframe: 'true',
    }).toString(),
  );
  return url.toString();
};
