import type { GetClickTrackingProductsURLExtensionProps } from '../../types';

/**
 * This function returns the correct URL extension for the products click tracking.
 */
export const getClickTrackingProductsURLExtension = ({
  sourceArticleNo,
  targetOrderNumber,
  cid,
  outputElementNo,
  position,
  pageTemplate,
  productsToLoad,
  recoLogic,
  rdeType,
}: GetClickTrackingProductsURLExtensionProps) => {
  // To support click tracking the URL has to be extended with the needed parameters
  const urlExtension = `&ref=reco&lmPromo=la,1,hk,${pageTemplate},fl,${rdeType}_${productsToLoad}_${position}_${cid}_${sourceArticleNo}_${targetOrderNumber}_${recoLogic}_OutputElement${outputElementNo}`;

  return urlExtension;
};
