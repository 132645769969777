import type { FormatNumberOptions } from 'react-intl';
import { useIntl } from 'react-intl';
import { logger } from '../../utils/logger/logger';
import { useConfig } from '../useConfig/useConfig';

type UseFormatPriceReturn = {
  /**
   * method to format a price value as integer to a customized price string
   *
   * @param price the price as Integer (in cents)
   * @param currency the currency for the price value
   * @param {boolean=} priceIsFloat is the price value a float value (Installment Calculator)
   * @returns string
   */
  formatPrice: (
    price: number,
    currency: FormatNumberOptions['currency'],
    priceIsFloat?: boolean,
  ) => string;
};

export const useFormatPrice = (): UseFormatPriceReturn => {
  const { formatNumber, locale } = useIntl();
  const { priceFormat } = useConfig();

  const formatPrice: UseFormatPriceReturn['formatPrice'] = (price, currency, priceIsFloat) => {
    switch (priceFormat) {
      /* 
        Empiriecom price format has ISO 
          delimiters (character at thousends), 
          Euro value,
          fraction (the character between Euro and Cent),
          Cent value,
          minusSign
      */
      /* 
        NoDelimiterCurrencyAtTheEnd price format has ISO 
          Euro value,
          fraction (the character between Euro and Cent),
          Cent value,
          minusSign
      */
      case 'Empiriecom':
      case 'NoDelimiterCurrencyAtTheEnd': {
        try {
          // Intl.Numberformat will simply throw if the currency or locale doesn't make sense
          const numberFormatParts = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency,
          }).formatToParts(priceIsFloat ? price : price / 100);

          const formattedCurrency = numberFormatParts.find(
            ({ type }) => type === 'currency',
          )!.value;

          return `${numberFormatParts
            .filter(
              ({ type }) =>
                (type === 'group' && priceFormat === 'Empiriecom') ||
                type === 'decimal' ||
                type === 'fraction' ||
                type === 'integer' ||
                type === 'minusSign',
            )
            .map(({ value: partValue }) => partValue)
            .join('')} ${formattedCurrency}`;
        } catch (error) {
          logger.error(
            { error, locale, currency },
            `unable to execute Intl.NumberFormat with locale ${locale} and currency ${currency}`,
          );
        }
        // if custom formatting fails, always return the default iso format
      }
      /* 
        Iso price format is using the whole ISO price formatting standard
      */
      // eslint-disable-next-line no-fallthrough
      case 'Iso':
      default: {
        return formatNumber(priceIsFloat ? price : price / 100, { style: 'currency', currency });
      }
    }
  };

  return {
    formatPrice,
  };
};
