import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgStar32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M16 25.173 6.42 31l2.487-11.071L.5 12.459l11.116-1.015L16 1l4.384 10.444L31.5 12.459l-8.407 7.47L25.58 31z" className="star-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgStar32);
export default Memo;