import type { SxProps } from '@mui/system';

export function getIconButtonStyles(isSelected: boolean): SxProps {
  return {
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 0,
    position: 'relative',
    padding: 0,
    outline: 0,

    '&:hover': {
      backgroundColor: 'transparent',
      '& > div': isSelected
        ? undefined
        : {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          },
    },
  };
}

export function getDotStyles(isSelected: boolean): SxProps {
  return {
    backgroundColor: isSelected ? '#e0e0e0' : '#fff',
    width: '14px',
    height: '14px',
    border: '1px solid #e0e0e0',
    borderRadius: '50%',
    transition: 'background-color 150ms ease-in-out',
  };
}
