import type { FC } from 'react';
import React from 'react';
import { Dot } from './Dot';
import { Stack } from '../../Stack';

export type CarouselDotNavType = {
  scrollSnaps: number[];
  selectedIndex: number;
  scrollTo: (index: number) => void;
};

export type CarouselDotType = {
  selected?: boolean;
  onClick: React.MouseEventHandler;
};

export const CarouselDotNav: FC<CarouselDotNavType> = ({
  scrollSnaps,
  selectedIndex,
  scrollTo,
}) => (
  <Stack direction="row" justifyContent="center">
    {scrollSnaps.map((_, index) => (
      <Dot
        key={`dot${index.toString()}`}
        isSelected={index === selectedIndex}
        onClickHandler={() => scrollTo(index)}
      />
    ))}
  </Stack>
);
