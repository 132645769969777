import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { Box, Card, Image, Link, Stack, Typography } from '@packages/shared';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { CategoryRecommendationFragmentFragmentDoc } from '@packages/gql/generated/shopping/CategoryRecommendationFragmentFragmentDoc';
import { getClickTrackingBrandsCategoriesURLExtension } from '../../helpers/getClickTrackingBrandsCategoriesURLExtension';
import type { PageTemplate, RdeType } from '../../types';

/* GraphQL */ `
  fragment CategoryRecommendationFragment on CategoryRecommendation {
    category {
      categoryId
      categoryLink
      name
      topsellerProduct {
        imageUrl
        productId
      }
    }
    tracking {
      token
      logic
    }
  }
`;

export type RecoCategoryCardProps = {
  /**
   * number of brands
   */
  categoriesLength: number;
  /**
   * pageTemplate for products tracking
   */
  pageTemplate: PageTemplate;
  /**
   * position of product
   */
  position: number;
  recommendationData: FragmentType<typeof CategoryRecommendationFragmentFragmentDoc>;
  /**
   * Recommendation type
   */
  rdeType: RdeType;
};

export const RecoCategoryCard = ({
  categoriesLength,
  recommendationData,
  pageTemplate,
  position,
  rdeType,
}: RecoCategoryCardProps) => {
  const dispatchGTMEvent = useTracking();
  const { category, tracking } = getFragmentData(
    CategoryRecommendationFragmentFragmentDoc,
    recommendationData,
  );

  return (
    <Card>
      <Link
        href={`${category.categoryLink}${
          category.categoryLink.endsWith('/') ? '' : '/'
        }?highlight=${
          category.topsellerProduct.productId
        }${getClickTrackingBrandsCategoriesURLExtension({
          cid: category.categoryId,
          itemsToLoad: categoriesLength,
          pageTemplate,
          recoLogic: tracking.logic,
          rdeType,
          name: category.name,
          position: position + 1,
        })}`}
        sx={{
          textDecoration: 'none',
          color: 'text.primary',
          '&:hover': {
            textDecoration: 'underline',
            color: 'primary.main',
          },
        }}
        onClick={() => {
          dispatchGTMEvent({
            event: 'ga_event',
            eventAction: 'click',
            eventCategory: 'recoClick',
            eventLabel: 'categoryRecoItemClick',
            eventValue: {
              trackingToken: tracking.token,
              pid: category.topsellerProduct.productId,
            },
          });
        }}
      >
        <Stack justifyContent="center">
          <Box sx={{ position: 'relative', height: 184 }}>
            <Image
              src={`${category.topsellerProduct.imageUrl}?$PL_square_hq$`}
              fill
              style={{ objectFit: 'cover', objectPosition: 'center center' }}
              // TODO: add alt text to data structure
              alt={`Image of category: ${category.name}`}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: 36,
            }}
          >
            <Typography
              title={category.name}
              component="p"
              variant="body2"
              sx={{
                px: 1.5,
                textAlign: 'center',
                overflow: 'hidden',
              }}
            >
              {category.name}
            </Typography>
          </Box>
        </Stack>
      </Link>
    </Card>
  );
};
