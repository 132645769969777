import { Box, Typography } from '@packages/shared';
import { Html } from '../Html';
import { LinkByButton } from '../LinkByButton';
import { LinkByText } from '../LinkByText';
import { SoftHyphenInjector } from '../SoftHyphenInjector';
import { mapButtonTypeToLayout } from '../../utils/mapButtonTypeToLayout';
import type { LinkBoxItemContentComponentType } from './types';

/**
 * The LinkBoxItemContent component displays content of the LinkBox
 */
export const LinkBoxItemContent = ({
  data: {
    // content
    field_subline_90: subline,
    field_headline_120: headline,
    field_html_editor: htmlContent,
    // call to action
    field_button_text: buttonText,
    // cta button
    behavior_paragraph_cta_icon: buttonIcon,
    behavior_paragraph_cta_size: buttonSize = 'small',
    behavior_paragraph_cta_type: buttonType = 'button-default',
    // Options:
    // 	• standard (without background color)
    // 	• boxed (with bg color)
    behavior_paragraph_variant: layoutVariant,
    // Glycerin Tracking
    behavior_paragraph_campaign_type: glycerinCampaignType,
    behavior_paragraph_campaignid: glycerinCampaginId,
    behavior_paragraph_category: glycerinCategory,
    behavior_paragraph_detail: glycerinDetail,
    behavior_paragraph_name: glycerinName,
  },
  link,
  linkTarget,
  isLandscape,
}: LinkBoxItemContentComponentType) => (
  <Box
    sx={{
      width: isLandscape ? '50%' : '100%',
      height: '100%',
      p: layoutVariant === 'boxed' ? '0.75rem' : '0.75rem 0',
    }}
  >
    {subline && (
      <Typography>
        <SoftHyphenInjector text={subline} />
      </Typography>
    )}
    {headline && (
      <Typography sx={{ mb: 1 }} typography="h4" fontWeight="600">
        <SoftHyphenInjector text={headline} />
      </Typography>
    )}
    {htmlContent && (
      <Box
        sx={{
          mb: 1,
        }}
      >
        <Html data={{ type: 'html', field_html_editor: htmlContent }} />
      </Box>
    )}
    {link !== '' && buttonText && buttonType && !['textlink', 'caption'].includes(buttonType) && (
      <LinkByButton
        data={{
          type: 'link_by_button',
          field_link: link,
          field_link_target: linkTarget,
          field_text: buttonText,
          field_layout: mapButtonTypeToLayout(buttonType),
          behavior_paragraph_size: buttonSize,
          behavior_paragraph_campaign_type: glycerinCampaignType,
          behavior_paragraph_campaignid: glycerinCampaginId,
          behavior_paragraph_category: glycerinCategory,
          behavior_paragraph_detail: glycerinDetail,
          behavior_paragraph_name: glycerinName,
        }}
        icon={buttonIcon ? 'ArrowRight' : undefined}
      />
    )}
    {link !== '' && buttonText && buttonType === 'textlink' && (
      <LinkByText
        data={{
          type: 'link_by_text',
          field_link: link,
          field_link_target: linkTarget,
          field_text: buttonText,
          field_icon: buttonIcon ? 'ArrowRight' : undefined,
          behavior_paragraph_campaign_type: glycerinCampaignType,
          behavior_paragraph_campaignid: glycerinCampaginId,
          behavior_paragraph_category: glycerinCategory,
          behavior_paragraph_detail: glycerinDetail,
          behavior_paragraph_name: glycerinName,
        }}
      />
    )}
  </Box>
);
