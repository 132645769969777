import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { ProductRecommendationFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductRecommendationFragmentFragmentDoc';
import { ProductCard } from '@packages/modules/src/ProductCard/ProductCard';
import type { EfficiencyFlagProps, ProductPriceProps } from '@packages/shared';
import type {
  GlycerinAdClickPayload,
  GlycerinProductClickPayload,
} from '@packages/tracking/src/types/payloads';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { defineMessages, useIntl } from 'react-intl';
import { getClickTrackingProductsURLExtension } from '../../helpers/getClickTrackingProductsURLExtension';
import { prepareEECproductClick } from '../../helpers/prepareEecProductClick';
import type { PageTemplate, RdeType } from '../../types';

/* GraphQL */ `
  fragment ProductRecommendationFragment on ProductRecommendation {
    product {
      availability {
        message
      }
      additionalFlags {
        src
        text
        type
      }
      akl
      brand {
        name
      }
      defaultCategory
      downloads {
        link
        type
      }
      link
      media {
        type
        url
      }
      name(type: NO_BRAND)
      orderNumber
      powerEfficiencyFlags {
        color
        hasOldLabel
        level
        link
        text
      }
      price {
        amount
        currency
        isStartPrice
        saving {
          type
          value
        }
        uvp
        unit
        value
        valueOld
        valueUnit
      }
      promotion
      sku
      tireEfficiency {
        flags {
          level
          type
        }
        link
      }
    }
    tracking {
      token
      trackingUrls {
        url
        purpose
      }
      logic
    }
  }
`;

/**
 * This maps the type from our product data to the correct format for the component `EfficiencyFlag`
 */
const tireEfficiencyTypeMap: { [key: string]: EfficiencyFlagProps['type'] } = {
  WETGRIP: 'wetGrip',
  FUELEFFICIENCY: 'fuelEfficiency',
  EXTERNALROLLINGNOISE: 'externalRollingNoise',
};

const messages = defineMessages({
  WETGRIP: {
    defaultMessage: 'Nasshaftung',
    id: 'recoProductCard.type.wetgrip',
  },
  FUELEFFICIENCY: {
    defaultMessage: 'Kraftstoffeffizienz',
    id: 'recoProductCard.type.fuelEfficiency',
  },
  EXTERNALROLLINGNOISE: {
    defaultMessage: 'Rollgeräusch',
    id: 'recoProductCard.type.externalRollingNoise',
  },
  uvp: {
    defaultMessage: 'UVP:',
    id: 'recoProductCard.price.uvp',
  },
  saving: {
    defaultMessage: 'statt',
    id: 'recoProductCard.price.saving',
  },
  datasheet: {
    defaultMessage: 'Produktdatenblatt',
    id: 'recoProductCard.datasheet',
  },
});

export type RecoProductCardProps = {
  /**
   * Reco Position on page.
   * If there are multiple recos you have to provide this prop (starting with 0 for first reco).
   *
   * @default 0
   */
  elementIndex: number;
  /**
   * pageTemplate for products tracking
   */
  pageTemplate: PageTemplate;
  /**
   * position of product
   */
  position: number;
  recommendationData: FragmentType<typeof ProductRecommendationFragmentFragmentDoc>;
  /**
   * number of products
   */
  productsLength: number;
  /**
   * Recommendation type
   */
  rdeType: RdeType;
  /**
   * Event handler for when a product is clicked.
   * This function is called when a user clicks a product element.
   */
  onClick?: (akl: string, sku: string) => void;
};

export const RecoProductCard = ({
  elementIndex,
  pageTemplate,
  position,
  recommendationData,
  onClick,
  productsLength,
  rdeType,
}: RecoProductCardProps) => {
  const { formatMessage } = useIntl();
  const dispatchGTMEvent = useTracking();
  const { product, tracking } = getFragmentData(
    ProductRecommendationFragmentFragmentDoc,
    recommendationData,
  );

  if (!product) return null;

  const { price } = product;

  const imageUrl = product.media.find((ele) => ele.type === 'IMAGE')?.url;

  const criteoLink = tracking.trackingUrls.find((ele) => ele.purpose === 'ProductPage')?.url;

  const datasheets = product.downloads
    .filter((download) => download.type === 'PRODUCT_DATASHEET')
    .map((download) => ({
      url: download.link,
      name: formatMessage(messages.datasheet),
    }));

  const additionalPowerFlags = product.powerEfficiencyFlags
    ? product.powerEfficiencyFlags.map(
        (flag): EfficiencyFlagProps => ({
          type: flag.hasOldLabel ? 'energyEfficiencyOld' : 'energyEfficiency',
          labelColor: flag.color as EfficiencyFlagProps['labelColor'],
          text: flag.level,
          overlay: { title: flag.text, imgUrl: flag.link },
          variant: 'small',
        }),
      )
    : [];

  const additionalTireFlags =
    product.tireEfficiency && product.tireEfficiency.flags
      ? product.tireEfficiency.flags.map(
          (flag): EfficiencyFlagProps => ({
            type: tireEfficiencyTypeMap[flag.type],
            text: flag.level,
            overlay: {
              title: formatMessage(messages[flag.type]),
              imgUrl: product.tireEfficiency!.link!,
            },
            variant: 'small',
          }),
        )
      : [];

  let clickTrackingProps: GlycerinAdClickPayload | GlycerinProductClickPayload;
  if (typeof criteoLink !== 'undefined') {
    clickTrackingProps = {
      adId: 'criteo',
      adType: 'criteo',
      category: 'criteo-card-click',
      target: criteoLink,
      label: `${product.brand?.name} ${product.name}`,
    };
  } else {
    clickTrackingProps = {
      category: 'product-click',
      productId: product.akl,
      articleId: product.orderNumber,
      detail: `${product.brand?.name} ${product.name}`,
      skuId: product.sku,
    };
  }

  return (
    <ProductCard
      sku={product.sku}
      minimal
      title={product.name}
      brand={product.brand?.name || ''}
      image={`${imageUrl}?$PL_square_hq$`}
      productLink={
        criteoLink ||
        `${product.link}${getClickTrackingProductsURLExtension({
          cid: product.defaultCategory || '',
          pageTemplate,
          rdeType,
          recoLogic: tracking.logic,
          sourceArticleNo: '',
          outputElementNo: elementIndex,
          productsToLoad: productsLength,
          targetOrderNumber: product.orderNumber,
          position: position + 1,
        })}`
      }
      wishListTrackingProps={{
        product: {
          productId: product.akl,
          articleId: `${product.orderNumber}${product.promotion}`,
          listPosition: position + 1,
          origin: rdeType,
          imageType: undefined,
          estimatedDelivery: product.availability.message,
        },
      }}
      isSponsored={typeof criteoLink !== 'undefined'}
      clickTrackingProps={clickTrackingProps}
      onClick={() => {
        dispatchGTMEvent(
          prepareEECproductClick({
            brand: product.brand?.name || '',
            list: rdeType,
            name: product.name,
            pid: product.orderNumber,
            position: position + 1,
            priceValue: price.value ?? 0,
            trackingToken: tracking.token || undefined,
          }),
        );
        onClick?.(product.akl, product.sku);
      }}
      // TODO: adapt ProductPrice props to data structure after ProductPrice is adapted and fragmentized
      price={{
        amount: price.amount ?? undefined,
        currency: price.currency,
        discountType: (price.saving?.type as ProductPriceProps['discountType']) ?? undefined,
        isStartPrice: !!price.isStartPrice,
        oldPrice: price.valueOld ?? undefined,
        price: price.value ?? 0,
        saving: price.saving?.value,
        small: true,
        unit: price.unit || undefined,
        uvpLabel: price.uvp
          ? formatMessage(messages.uvp)
          : (price.saving !== null && formatMessage(messages.saving)) || undefined,
        unitPrice: price.valueUnit || undefined,
      }}
      // flags are being disabled for now, since the tenants are working on a better solution for displaying them
      // flags={product.additionalFlags.map(({ text, type }) => ({
      //   text,
      //   type: type as FlagProps['type'],
      // }))}
      efficiencyFlags={
        additionalPowerFlags.length > 0 || additionalTireFlags.length > 0
          ? [...additionalPowerFlags, ...additionalTireFlags]
          : undefined
      }
      shrinkToFit
      datasheets={datasheets}
    />
  );
};
