import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgEnergyEfficiency = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 20" sx={{
    ...sx
  }} {...other}><path fill="currentColor" d="M26.1 0H0v20h26.1L35 9.6z" /><path fill="#fff" d="M0 0h8v20H0z" /><path d="M4.1 16.5h1v.6c-.1.1-.3.2-.5.3s-.3.1-.5.1c-.3 0-.6-.1-.8-.4s-.4-.8-.4-1.4.1-1 .3-1.3.5-.4.9-.4c.2 0 .4.1.6.2s.3.3.3.6l.8-.2c-.1-.5-.3-.8-.6-1.1s-.7-.4-1.2-.4c-.4 0-.8.1-1.1.3s-.4.6-.6 1-.3.8-.3 1.4c0 .5.1 1 .3 1.4s.4.7.7.9.7.3 1.1.3c.3 0 .7-.1 1-.2s.6-.3.8-.5v-2H4.1zM3.2 5.4h1.6l.4 1.1h.9l-1.6-5h-.9L2 6.5h.9zm.9-2.7.6 1.9H3.5zm1.5 6.2L4 7.5 2.3 8.9l.6.6.7-.6v3.3h.8V8.9l.6.6z" /><path d="M26.1 20H0V0h26.1l9 9.6zM.5 19.5h25.3l8.6-9.9L25.8.5H.5z" /></SvgIcon>;
};
const Memo = memo(SvgEnergyEfficiency);
export default Memo;