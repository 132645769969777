import type { ButtonType, Layout } from '../../interfaces';

export const mapButtonTypeToLayout = (buttonType: ButtonType): Layout => {
  switch (buttonType) {
    case 'button-primary':
      return 'primary';
    case 'button-secondary':
      return 'secondary';
    default:
      return 'base';
  }
};
