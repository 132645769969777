import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  headline: {
    id: 'HermesShopfinder.Headline',
    defaultMessage: 'Hermes Paketshop finden',
  },
  iFrameTitle: {
    id: 'HermesShopfinder.iFrameTitle',
    defaultMessage: 'Paketshop Finder',
  },
});
