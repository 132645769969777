import { Box, Dialog } from '@packages/shared';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { messages } from './messages';
import { useShopfinderUrl } from './useShopfinderUrl';

export const ReplaceComponent = ({
  index,
  placeholderText,
}: {
  index: number;
  placeholderText: string;
}) => {
  const { formatMessage } = useIntl();
  const [stateOpen, setStateOpen] = useState<boolean>(false);

  return (
    <span key={index}>
      <Box
        component="span"
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => setStateOpen(true)}
      >
        {placeholderText}
      </Box>
      <Dialog
        open={stateOpen}
        title={formatMessage(messages.headline)}
        handleClose={() => setStateOpen(false)}
        dialogContent={
          <iframe
            title={formatMessage(messages.iFrameTitle)}
            width="100%"
            height="600"
            style={{ border: 0, overflow: 'hidden' }}
            src={useShopfinderUrl()}
          />
        }
      />
    </span>
  );
};
