import DOMPurify from '@packages/config/utils/dompurify';
import reactStringReplace from 'react-string-replace';
import { ComponentTypes } from '../../../interfaces';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import type { HermesShopFinderType } from './types';
import { ReplaceComponent } from './ReplaceComponent';

/**
 * Component that initializes a modal layer from a text from a CMS application with a placeholder.
 * Based on Shared Dialog: @packages/shared/Dialog
 *
 * @param {Object} props - The properties passed to the component.
 * @param {HermesShopFinderType} props.data - The data used to initialize the HermesShopFinder.
 *
 * @returns {JSX.Element} The rendered HermesShopfinderChild component.
 */

export const HermesShopFinder = ({
  data: {
    field_placeholder_id: placeholderId,
    field_placeholder_text: placeholderText,
    field_html_editor: replaceText,
  },
}: {
  data: HermesShopFinderType;
}) => (
  <>
    {reactStringReplace(
      // strip the default drupal paragraph tags
      replaceText.replace(/^<p>/, '').replace(/<\/p>/, ''),
      placeholderId,
      (_, index) => <ReplaceComponent index={index} placeholderText={placeholderText} />,
    ).map((chunk, index) =>
      // iterate over the returned array and render the chunks (that stayed a string) as html
      typeof chunk === 'string' ? (
        // eslint-disable-next-line react/no-array-index-key, react/no-danger
        <span key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(chunk) }} />
      ) : (
        chunk
      ),
    )}
  </>
);

registerComponents({
  [ComponentTypes.HermesShopFinder]: HermesShopFinder,
});
