import type { BridgeTypes } from './types';

declare global {
  interface Window {
    Bridge: BridgeTypes;
  }
}

/* 
  IMPORTANT

  This has to be a method! The check has to be done when a Bridge method is called.
  Otherwise it could be, that the Bridge object is'nt available in window when this utility method gets imported. That could cause a runtime issue.
*/
export const getBridge = (): BridgeTypes =>
  typeof window !== 'undefined' && typeof window.Bridge !== 'undefined'
    ? window.Bridge // return Bridge object if it exists
    : {
        // return dummy object if Bridge does not exist
        // methods retuning void without attributes
        updateBasket: () => {},
        updateWishlist: () => {},
        showMyAccount: () => {},
        showHomePage: () => {},
        getBasketFreezeObj: () => {},
        showConsentManager: () => {},
        rejectConsent: () => {},
        // methods retuning void with attributes
        setMasterToken: () => {},
        setSessionToken: () => {},
        orderConfirmation: () => {},
        setBonuscodes: () => {},
        setTitle: () => {},
        showDetailView: () => {},
        setExternalCallInfoObj: () => {},
        setLastAffiliateObj: () => {},
        setBasketFreezeObj: () => {},
        getTransactionParameters: () => {},
        setUserProperties: () => {},
        downloadDocument: () => {},
        trackFirebaseEvent: () => {},
        // methods returning something without attributes
        getMasterToken: () => '',
        getSessionToken: () => '',
        getBonuscodes: () => '',
        getExternalCallInfoObj: () => '',
        getLastAffiliateObj: () => '',
        getSessionID: () => '',
      };
