import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgArrowMediumRight32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M12.474 10.801a.49.49 0 0 1 .005-.699l.958-.958a.503.503 0 0 1 .7-.005l6.859 6.859-6.859 6.858a.491.491 0 0 1-.7-.005l-.958-.958a.499.499 0 0 1-.005-.7l5.196-5.195z" className="arrow-medium-right-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgArrowMediumRight32);
export default Memo;