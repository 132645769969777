import { Typography } from '@packages/shared';

type TitleProps = { minimal?: boolean; children: string };

export const Title = ({ minimal, children }: TitleProps) => (
  <Typography
    variant="body2"
    mb={2}
    sx={
      minimal
        ? {
            overflow: 'hidden',
            display: '-webkit-box',
            // shows two lines of headline in minimal version
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }
        : { flexGrow: 1 }
    }
  >
    {children}
  </Typography>
);
