import { Box, Skeleton, Stack } from '@packages/shared';

export const RecoCategoryCardSkeleton = () => (
  <Stack direction="column" width="100%">
    <Skeleton variant="rectangular" height={184} width="100%" />
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 36 }}>
      <Skeleton variant="rectangular" height={20} width="100%" />
    </Box>
  </Stack>
);
