import { useCookieConsent } from '@packages/shared/src/hooks/useCookieConsent/useCookieConsent';
import { useEffect } from 'react';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';

/**
 * This component represents external JavaScript resources to be used on content pages.
 * This component delivers product content generated by HappyContest.
 *
 * @param src String contains happy Contest Source
 * @param id String contains happy Contest Identifier
 * @returns JSX.Element to be placed in PageLayout
 */
export const Flixmedia = ({ descriptor }: { descriptor: string }) => {
  const isAllowed: boolean = useCookieConsent('C0004');
  const dispatchGTMEvent = useTracking();

  useEffect(() => {
    if (isAllowed) {
      dispatchGTMEvent({
        event: 'RenderFlixmedia',
        descriptor,
      });
    }
  }, [descriptor, dispatchGTMEvent, isAllowed]);

  return <div id="flix-microsite" />;
};
