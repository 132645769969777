import type { SxProps, Theme } from '@mui/material';
import { Box } from '@packages/shared';
import type { Distance, MarginVariant, ParagraphMeta } from '../../../interfaces';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';

export type SectionSeperatorType = ParagraphMeta & {
  type: 'section_seperator';
  field_margin_variant: MarginVariant;
  field_distance: Distance;
  field_type?: 'section_seperator';
  field_style_type?: 'default';
};

type SectionSeperatorProps = {
  data: SectionSeperatorType;
  sx?: SxProps<Theme>;
};

export const marginVariants = {
  big: '4rem 0',
  medium: '2rem 0',
  small: '1rem 0',
  none: '0',
};

export enum SectionSeperatorTestIds {
  main = 'sectionseperator',
}

export const SectionSeperator = ({ data, sx }: SectionSeperatorProps) => {
  const { field_margin_variant: marginVariant } = data;

  return (
    <Box
      sx={{
        width: '100%',
        margin: marginVariants[marginVariant],
        padding: 0,
        height: '0.0625rem',
        backgroundColor: 'grey.main',
        boxShadow: (theme) => `inset 0 -0.5px 0 0 ${theme.palette.grey.dark}`,
        ...sx,
      }}
      data-testid={SectionSeperatorTestIds.main}
    />
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.SectionSeperator]: SectionSeperator });
