import type { PageTemplate } from '@packages/recommendations';
import {
  LastSeenProductsRecoSlider,
  PrudsysCategoryToBrandsRecoSlider,
  PrudsysCategoryToCategoriesRecoSlider,
  PrudsysCategoryToProductsRecoSlider,
  PrudsysHomepageProductsRecoSlider,
  CriteoViewHomeProductsRecoSlider,
} from '@packages/recommendations';
import { Box } from '@packages/shared';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';
import type { RecommendationType } from './types';

const Reco = ({
  data,
  pageTemplate,
  elementIndex = 0,
}: {
  data: RecommendationType;
  elementIndex?: number;
  pageTemplate: PageTemplate;
}) => {
  const {
    field_reco_service: recoServiceType,
    field_shop_id: categoryId = '',
    field_auto_headline_disabled: disableAutoHeadline,
  } = data;

  switch (recoServiceType) {
    case 'prudsysCategoryToProducts':
    case 'prudsysCategoryToProductsPapi':
      return (
        <PrudsysCategoryToProductsRecoSlider
          categoryId={categoryId}
          pageTemplate={pageTemplate}
          elementIndex={elementIndex}
          title=""
          preferTitle={disableAutoHeadline}
        />
      );

    case 'prudsysCategoryToCategories':
      return (
        <PrudsysCategoryToCategoriesRecoSlider
          categoryId={categoryId}
          sourceCategoryId="1"
          pageTemplate={pageTemplate}
          elementIndex={elementIndex}
          title=""
          preferTitle={disableAutoHeadline}
        />
      );

    case 'prudsysCategoryToBrands':
    case 'prudsysCategoryToBrandsPapi':
      return (
        <PrudsysCategoryToBrandsRecoSlider
          categoryId={categoryId}
          sourceCategoryId="1"
          pageTemplate={pageTemplate}
          elementIndex={elementIndex}
          title=""
        />
      );

    case 'prudsysUserToProductsPapi':
    case 'prudsysHomePage': // in drupal: UserToProductsPrudsys
      return (
        <PrudsysHomepageProductsRecoSlider
          pageTemplate={pageTemplate}
          elementIndex={elementIndex}
          title=""
          preferTitle={disableAutoHeadline}
        />
      );
    case 'lastSeen':
      return (
        <LastSeenProductsRecoSlider
          pageTemplate={pageTemplate}
          elementIndex={elementIndex}
          fetchOnThreshold
          title=""
        />
      );
    case 'criteo':
      return (
        <CriteoViewHomeProductsRecoSlider
          elementIndex={elementIndex}
          pageTemplate={pageTemplate}
          title=""
        />
      );
    default:
      return <span data-testid="cms-reco-no-reco-type-found" />;
  }
};

export const CmsRecommendation = ({
  data,
  recoIndex = 0,
  pageTemplate = 'homepage',
}: {
  data: RecommendationType;
  recoIndex?: number;
  pageTemplate?: PageTemplate;
}) => {
  const { field_margin: margin } = data;

  return (
    // use margin from cms. if no marging has been given by cms, set a margin-bottom of 8.
    <Box sx={{ margin, marginBottom: !margin ? 8 : undefined }}>
      <Reco data={data} pageTemplate={pageTemplate} elementIndex={recoIndex} />
    </Box>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.RecoPrudsys]: CmsRecommendation });
