import type { Font, LegacyFont } from '../../interfaces';

// The fontMapper is an object that maps from legacy font types to the new font types.
// Each key is a LegacyFont and its corresponding value is the new Font that it should be replaced with.
const fontMapper: Record<LegacyFont, Font> = {
  GothamSSm3r: 'MontserratRegular',
  Gotham4r: 'MontserratSemibold',
  GothamSSm4r: 'MontserratSemibold',
  GothamSSm5r: 'MontserratBold',
  Gotham7r: 'MontserratExtraBold',
  GothamSSm7r: 'MontserratExtraBold',
  GothamNarrow: 'MontserratItalic',
};

// isLegacyFont is a user-defined type guard.
// It checks if the given font is a LegacyFont by checking if it exists as a key in the fontMapper object.
// The function returns true if the font exists in fontMapper (i.e., it is a LegacyFont), and false otherwise.
const isLegacyFont = (font: Font | LegacyFont | undefined): font is LegacyFont =>
  font !== undefined && Object.prototype.hasOwnProperty.call(fontMapper, font);

// The fontSwitcher function converts a legacy font type to the new font type.
// If a new font type is passed in, it is returned unchanged.
export const fontSwitcher = (font?: LegacyFont | Font): Font | undefined => {
  // If no font is provided, return undefined
  if (!font) return undefined;

  // If the provided font is a LegacyFont (i.e., it exists in fontMapper), use the fontMapper
  // to get the corresponding new Font and return it.
  if (isLegacyFont(font)) {
    return fontMapper[font]; // No need to cast here, TypeScript knows font is LegacyFont
  }

  // If the provided font is not a LegacyFont (i.e., it does not exist in fontMapper),
  // we assume that it's already a new Font type and return it unchanged.
  return font as Font;
};
