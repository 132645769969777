import type { SxProps, Theme } from '@mui/system';
import config from '@packages/config';
import { FontSizeVariants, LineHeightVariants } from './types';
import {
  StyleType1aBasics,
  StyleType1bBasics,
  StyleType2aBasics,
  StyleType2bBasics,
  StyleType3aBasics,
  StyleType3bBasics,
  StyleType4aBasics,
  StyleType4bBasics,
} from './styles';

export const styleHelperFontSize = (type: string) => {
  switch (type) {
    case 'big':
      return FontSizeVariants.Big;
    case 'medium':
      return FontSizeVariants.Medium;
    default:
      return FontSizeVariants.Small;
  }
};

export const styleHelperLineHeight = (type: string) => {
  switch (type) {
    case 'big':
      return LineHeightVariants.Big;
    case 'medium':
      return LineHeightVariants.Medium;
    default:
      return LineHeightVariants.Small;
  }
};

export const styleFinalVerticalAlignment = (
  styleHelperVerticalAlignment: string,
  transformBasicStyle: { xs: string; md: string; lg: string },
) => {
  const styleTranslateY = ['top', 'bottom'].includes(styleHelperVerticalAlignment)
    ? ''
    : 'translateY(-50%)';
  const styleMerged = {
    xs: `${transformBasicStyle.xs !== 'none' ? `${transformBasicStyle.xs} ` : ''}${styleTranslateY !== '' ? `${styleTranslateY}` : ''}`,
    md: `${transformBasicStyle.md !== 'none' ? `${transformBasicStyle.md} ` : ''}${styleTranslateY !== '' ? `${styleTranslateY}` : ''}`,
    lg: `${transformBasicStyle.lg !== 'none' ? `${transformBasicStyle.lg} ` : ''}${styleTranslateY !== '' ? `${styleTranslateY}` : ''}`,
  };

  switch (styleHelperVerticalAlignment) {
    case 'top':
      return { top: '11%', transform: styleMerged };
    case 'bottom':
      return { bottom: '11%', transform: styleMerged };
    default:
      return { top: '50%', transform: styleMerged };
  }
};

export const styleHelperType = (
  styleType: string,
  styleHelperVerticalAlignment: string,
  transformBasicStyle: { xs: string; md: string; lg: string },
): SxProps<Theme> => {
  const styleVerticalBasic = styleFinalVerticalAlignment(
    styleHelperVerticalAlignment,
    transformBasicStyle,
  );

  const styleTypeDefault = {
    ...StyleType3aBasics,
    ...styleVerticalBasic,
    transformOrigin: 'left',
  };

  switch (styleType) {
    case '1a':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType1aBasics,
          ...styleVerticalBasic,
          transformOrigin: 'top left',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType1aBasics,
          ...styleVerticalBasic,
          transformOrigin: 'bottom left',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return {
          ...StyleType1aBasics,
          ...styleVerticalBasic,
          transformOrigin: 'left',
        };
      }
      return styleTypeDefault;
    case '1b':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType1bBasics,
          ...styleVerticalBasic,
          transformOrigin: 'top right',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType1bBasics,
          ...styleVerticalBasic,
          transformOrigin: 'bottom right',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return {
          ...StyleType1bBasics,
          ...styleVerticalBasic,
          transformOrigin: 'right',
        };
      }
      return styleTypeDefault;
    case '2a':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType2aBasics,
          ...styleVerticalBasic,
          transformOrigin: 'top left',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType2aBasics,
          ...styleVerticalBasic,
          transformOrigin: 'bottom left',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return {
          ...StyleType2aBasics,
          ...styleVerticalBasic,
          transformOrigin: 'bottom left',
        };
      }
      return styleTypeDefault;
    case '2b':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType2bBasics,
          ...styleVerticalBasic,
          transformOrigin: 'top right',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType2bBasics,
          ...styleVerticalBasic,
          transformOrigin: 'bottom right',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return {
          ...StyleType2bBasics,
          ...styleVerticalBasic,
          transformOrigin: 'right',
        };
      }
      return styleTypeDefault;
    case '3a':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType3aBasics,
          ...styleVerticalBasic,
          transformOrigin: 'top left',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType3aBasics,
          ...styleVerticalBasic,
          transformOrigin: 'bottom left',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return styleTypeDefault;
      }
      return styleTypeDefault;
    case '3b':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType3bBasics,
          ...styleVerticalBasic,
          transformOrigin: 'top right',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType3bBasics,
          ...styleVerticalBasic,
          transformOrigin: 'bottom right',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return {
          ...StyleType3bBasics,
          ...styleVerticalBasic,
          transformOrigin: 'right',
        };
      }
      return styleTypeDefault;
    case '4a':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType4aBasics,
          ...styleVerticalBasic,
          transformOrigin: 'top left',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType4aBasics,
          ...styleVerticalBasic,
          transformOrigin: 'bottom left',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return {
          ...StyleType4aBasics,
          ...styleVerticalBasic,
          transformOrigin: 'left',
        };
      }
      return styleTypeDefault;
    case '4b':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType4bBasics,
          ...styleVerticalBasic,
          transformOrigin: 'top right',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType4bBasics,
          ...styleVerticalBasic,
          transformOrigin: 'bottom right',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return {
          ...StyleType4bBasics,
          ...styleVerticalBasic,
          transformOrigin: 'right',
        };
      }
      return styleTypeDefault;
    default:
      return styleTypeDefault;
  }
};

export const styleHelperAlignment = (alignment: string) => {
  switch (alignment) {
    case 'left':
      return { left: 0 };
    case 'right':
      return { right: 0 };
    default:
      return { left: '50%' };
  }
};

export const styleHelperVerticalAlignment = (verticalAlignment: string) => {
  switch (verticalAlignment) {
    case 'center':
    case 'stretch':
      return {
        top: '50%',
        transform: 'translateY(-50%)',
      };
    case 'flex-end':
    case 'bottom':
      return { bottom: 0 };
    default:
      // top, flex-start
      return { top: 0 };
  }
};

export const containerStyle = (
  styleType: string,
  styleAlignment: string,
  styleVerticalAlignment: string,
  styleBorderColor: string,
  styleBackgroundColor: string,
  styleScalingFactor: number,
): SxProps<Theme> => {
  const transformBasicStyle = {
    xs:
      !styleScalingFactor || styleScalingFactor === 1
        ? 'none'
        : `scale(${styleScalingFactor - 0.33})`,
    md:
      !styleScalingFactor || styleScalingFactor === 1
        ? 'none'
        : `scale(${styleScalingFactor - 0.3})`,
    lg: !styleScalingFactor || styleScalingFactor === 1 ? 'none' : `scale(${styleScalingFactor})`,
  };

  return {
    '&': config.staticContent.cmsFontToTheme,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: 2,
    overflow: 'hidden',
    textAlign: 'left',
    position: 'absolute',
    textDecoration: 'none',
    '> a': {
      textDecoration: 'none',
    },
    border: `2px solid ${styleBorderColor}`,
    backgroundColor: styleBackgroundColor,
    justifyContent: styleAlignment,
    alignContent: styleVerticalAlignment,
    ...styleHelperType(styleType, styleVerticalAlignment, transformBasicStyle),
  };
};
