import React from 'react';

import type { FC } from 'react';
import type { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import type { CarouselThumbnailConfig } from '../types';

import {
  carouselViewportStyles,
  getCarouselChildWrapperStyles,
  getCarouselFlexWrapperStyles,
  getCarouselWrapperStyles,
} from '../styles';

import { Box } from '../../Box';

export type CarouselThumbNavType = {
  emblaMain: EmblaCarouselType;
  selectedIndex: number;
  thumbnailConfig: CarouselThumbnailConfig;
};

export const CarouselThumbNav: FC<CarouselThumbNavType> = ({
  emblaMain,
  selectedIndex,
  thumbnailConfig,
}) => {
  // Thumbnail Carousel Settings
  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true,
  });

  // Thumbnail Carousel Event Handler
  const onThumbClick = (index: number) => emblaMain.scrollTo(index);

  // init everything
  React.useEffect(() => {
    emblaMain.on('select', () => {
      if (emblaThumbs?.scrollTo) {
        emblaThumbs.scrollTo(emblaMain.selectedScrollSnap());
      }
    });
  }, [emblaMain, emblaThumbs]);

  const { thumbnails, gap = 1.25, visibleSlides = 'auto' } = thumbnailConfig;

  return (
    <Box data-testid="thumbnav" sx={getCarouselWrapperStyles()}>
      <Box ref={thumbViewportRef} data-index={selectedIndex} sx={carouselViewportStyles}>
        <Box sx={getCarouselFlexWrapperStyles(gap)}>
          {thumbnails.map((thumb, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index} sx={getCarouselChildWrapperStyles(visibleSlides, gap)}>
              <Box
                onClick={() => onThumbClick(index)}
                sx={{
                  touchAction: 'manipulation',
                  cursor: 'pointer',
                  width: '100%',
                  height: 'auto',
                  position: 'relative',
                  display: 'block',
                  overflow: 'hidden',
                  transition: 'opacity 200ms ease-out',
                  opacity: index === selectedIndex ? 1 : 0.2,
                  ...(typeof thumbnailConfig.thumbnailBoxStyles === 'function'
                    ? thumbnailConfig.thumbnailBoxStyles(index === selectedIndex)
                    : undefined),
                }}
              >
                {thumb}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
