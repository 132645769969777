import type { router } from 'next/client';

const DUMMY_ORIGIN = 'http://dummy.dummy';

/**
 * Merge additional query parameters into the given URL
 * - `string` values are added/overwritten
 * - `string[]` values are appended
 * - `undefined` values are removed
 *
 * @param url The URL to merge the query parameters into
 * @param additionalQuery The additional query parameters to merge
 * @returns The URL with the merged query parameters
 *
 * @example
 * // returns '/search?genre=non-fiction&genre=fiction&genre=mystery&sort=price'
 * mergeQueryParameters('/search?q=books&genre=non-fiction', {
 *  genre: ['fiction', 'mystery'],
 *  sort: 'price',
 *  q: undefined,
 * });
 */
export const mergeQueryParameters = (url: string, additionalQuery: typeof router.query) => {
  // some URLs we get from the API are protocol-relative (starting with '//')
  // protocol-relative URLs don't work with whatwg standard URL object
  // https is assumed as the default protocol
  const sanitizedUrl = url.replace(/^\/\//, 'https://');

  const isAbsolute = /^https?:\/\//.test(sanitizedUrl);

  const newUrl = new URL(sanitizedUrl, isAbsolute ? undefined : DUMMY_ORIGIN); // dummy URL, just to use the URLSearchParams API
  const searchParams = new URLSearchParams(newUrl.search);

  for (const key in additionalQuery) {
    if (additionalQuery) {
      const value = additionalQuery[key];
      if (value === undefined) {
        searchParams.delete(key);
      } else if (typeof value === 'string') {
        searchParams.set(key, value);
      } else if (Array.isArray(value)) {
        value.forEach((item) => {
          if (typeof item === 'string') {
            searchParams.append(key, item);
          }
        });
      }
    }
  }

  searchParams.sort();

  newUrl.search = searchParams.toString();

  return isAbsolute ? newUrl.toString() : newUrl.toString().replace(DUMMY_ORIGIN, '');
};
