import { Typography, ErrorBoundary, Stack } from '@packages/shared';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useIntl } from 'react-intl';
import useSWR from 'swr';
import { messages } from './Voucher.messages';
import { fetcher } from '../../utils';
import { CmsLoader } from '../CmsLoader';
import { VoucherItem } from './VoucherItem';
import type { VoucherItemType, VoucherType } from './types';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';

/**
 * Component that displays active or expired coupons
 *
 * @param data Object contains data
 * @returns JSX.Element to be placed in PageLayout
 */
const VoucherInit = ({ promotionStatus }: { promotionStatus: string }) => {
  const { formatMessage, formatDate, locale } = useIntl();
  const {
    voucher: { apiEndpoints },
  } = useConfig();
  const isExpired = promotionStatus === 'expired';

  const { data, isLoading } = useSWR<VoucherItemType[]>(
    `${apiEndpoints.api}/${promotionStatus}?locale=${locale}`,
    fetcher,
  );

  if (isLoading) {
    return <CmsLoader />;
  }

  if (data && data.length > 0) {
    return (
      <Stack spacing={4} alignItems="center" sx={{ backgroundColor: 'grey.light', padding: 4 }}>
        {data.map((voucher: VoucherItemType) => {
          const { voucherValue, validUntil, minOrderValue, customerGroup } = voucher;
          const detailInfos = [
            { key: formatMessage(messages.voucherValue), value: voucherValue || '' },
            {
              key: formatMessage(messages.voucherValidUntil),
              // multiply by 1000 here becaurse the php-timestamp (which we get here) is shortened by 1000 in difference to the JavaScript-timestamp
              value: (validUntil && formatDate(new Date(parseInt(validUntil, 10) * 1000))) || '',
            },
            { key: formatMessage(messages.voucherMinOrderValue), value: minOrderValue || '' },
            { key: formatMessage(messages.voucherCustomerGroup), value: customerGroup || '' },
          ];

          const finalData = {
            ...voucher,
            detailInfos,
          };
          return (
            <VoucherItem key={voucher.drupal_id} voucherData={finalData} expired={isExpired} />
          );
        })}
      </Stack>
    );
  }

  return (
    <Stack spacing={4} alignItems="center" sx={{ backgroundColor: 'grey.light', padding: 4 }}>
      <Typography variant="h4">
        {isExpired
          ? formatMessage(messages.noDataForExpired)
          : formatMessage(messages.noDataForActive)}
      </Typography>
    </Stack>
  );
};

export const Voucher = ({ data }: { data: VoucherType }) => {
  const { formatMessage } = useIntl();
  const { field_promotion_status: promotionStatus } = data;
  // Render an ErrorBoundary component to catch errors and display an error message
  // If an error occurs, render a Typography component with the error message
  return (
    <ErrorBoundary
      fallback={<Typography variant="h4">{formatMessage(messages.errorLoading)}</Typography>}
    >
      <VoucherInit promotionStatus={promotionStatus} />
    </ErrorBoundary>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.VoucherList]: Voucher });
