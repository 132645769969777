import { ComponentTypes } from '../../../interfaces/components';
import { CmsAccordion } from '../CmsAccordion';
import { CmsDynamicYield } from '../CmsDynamicYield';
import { CmsGroup } from '../CmsGroup';
import { CmsHeadline } from '../Headline';
import { CmsInstallmentCalculator } from '../CmsInstallmentCalculator';
import { CmsRecommendation } from '../CmsRecommendation';
import { CmsCarousel } from '../CmsCarousel';
import { CmsImage } from '../CmsImage';
import { CategoryTeaser } from '../CategoryTeaser';
import { ExternalScript } from '../ExternalScript';
import { OverlayRectangle } from '../OverlayRectangle';
import { Html } from '../Html';
import { LinkByButton } from '../LinkByButton';
import { LinkBox } from '../LinkBox';
import { LinkByText } from '../LinkByText';
import { LegacyNewsletter, NewsletterUnsubscribe } from '../Forms/Newsletter';
import { ModalByButton } from '../ModalByButton';
import { Placeholder } from '../Placeholder';
import { SectionSeperator } from '../SectionSeperator';
import { Voucher } from '../Voucher';
import { Video } from '../Video';
import { BlockText } from '../Footer/BlockText';
import { BlockList } from '../Footer/BlockList';
import { BlockImage } from '../Footer/BlockImage';
import { BlockBadges } from '../Footer/BlockBadges';
import { ImageGroup } from '../Footer/ImageGroup';
import { registerComponents } from './componentRegistry';
import { HermesShopFinder } from '../HermesShopFinder';
import { OverlayCircle } from '../OverlayCircle';
import { CmsEpsRecommendation } from '../CmsEpsRecommendation';

/**
 * Registers a set of components using the previously defined registerComponent function.
 * don't forget to register the dynamic components if <ComponentRecognizer /> is in use. Example:
 * ```
 *    import { registerFlexibleComponents } from '@packages/cms-components/src/components/ComponentRecognizer/registerFlexibleComponents';
 *    registerFlexibleComponents();
 * ```
 */
export const registerFlexibleComponents = (): void => {
  registerComponents({
    [ComponentTypes.Html]: Html,
    [ComponentTypes.Placeholder]: Placeholder,
    [ComponentTypes.Group]: CmsGroup,
    [ComponentTypes.GroupLocalizable]: CmsGroup,
    [ComponentTypes.GroupApp]: CmsGroup,
    [ComponentTypes.SvgFile]: CmsImage,
    [ComponentTypes.ImageSimple]: CmsImage,
    [ComponentTypes.ImageFooter]: CmsImage,
    [ComponentTypes.Headline]: CmsHeadline,
    [ComponentTypes.Accordion]: CmsAccordion,
    [ComponentTypes.AccordionLocalizable]: CmsAccordion,
    [ComponentTypes.LinkByButton]: LinkByButton,
    [ComponentTypes.LinkByText]: LinkByText,
    [ComponentTypes.LinkBox]: LinkBox,
    [ComponentTypes.DynamicYield]: CmsDynamicYield,
    [ComponentTypes.RecoPrudsys]: CmsRecommendation,
    [ComponentTypes.RecoEps]: CmsEpsRecommendation,
    [ComponentTypes.SectionSeperator]: SectionSeperator,
    [ComponentTypes.OverlayCircle]: OverlayCircle,
    [ComponentTypes.OverlayRectangle]: OverlayRectangle,
    [ComponentTypes.InstallmentCalculator]: CmsInstallmentCalculator,
    [ComponentTypes.ButtonModal]: ModalByButton,
    [ComponentTypes.Liveshopping]: ExternalScript,
    [ComponentTypes.ExternalScript]: ExternalScript,
    [ComponentTypes.ExternalScriptHappyContest]: ExternalScript,
    [ComponentTypes.ExternalScriptLoadbee]: ExternalScript,
    [ComponentTypes.Newsletter]: LegacyNewsletter,
    [ComponentTypes.NewsletterUnsubscribe]: NewsletterUnsubscribe,
    [ComponentTypes.VoucherList]: Voucher,
    [ComponentTypes.Video]: Video,
    [ComponentTypes.Carousel]: CmsCarousel,
    [ComponentTypes.BlockText]: BlockText,
    [ComponentTypes.BlockList]: BlockList,
    [ComponentTypes.BlockImage]: BlockImage,
    [ComponentTypes.BlockBadges]: BlockBadges,
    [ComponentTypes.FooterGroupImage]: ImageGroup,
    [ComponentTypes.CategoryTeaser]: CategoryTeaser,
    [ComponentTypes.HermesShopFinder]: HermesShopFinder,
  });
};
