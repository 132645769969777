import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgExternalRollingNoise32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" data-name="Ebene 1" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path fill="#262729" d="M18.85 6.26a1 1 0 0 1 .15.52v18.44a1 1 0 0 1-1 1 1 1 0 0 1-.52-.15L10.82 22H2a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h8.82l6.66-4.07a1 1 0 0 1 1.37.33m10.63.59A31.68 31.68 0 0 1 31 16a31.68 31.68 0 0 1-1.53 9.15.5.5 0 1 1-1-.3A30.67 30.67 0 0 0 30 16a30.67 30.67 0 0 0-1.49-8.85.5.5 0 1 1 1-.3ZM18 6.78 11.1 11H2v10h9.1l6.9 4.22Zm7.47 2A20 20 0 0 1 27 16a20 20 0 0 1-1.54 7.18.5.5 0 0 1-.94-.36A18.82 18.82 0 0 0 26 16a18.82 18.82 0 0 0-1.48-6.82.5.5 0 1 1 .94-.36Zm-4 1.94A11.09 11.09 0 0 1 23 16a11.09 11.09 0 0 1-1.57 5.24.5.5 0 1 1-.88-.48A10.06 10.06 0 0 0 22 16a10.06 10.06 0 0 0-1.45-4.76.5.5 0 0 1 .88-.48Z" /></SvgIcon>;
};
const Memo = memo(SvgExternalRollingNoise32);
export default Memo;