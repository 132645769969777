import useSWR from 'swr';
import { useIntl } from 'react-intl';
import { useLoginState } from '../useLoginState/useLoginState';
import { useCustomerApi } from '../useCustomerApi/useCustomerApi';

export type { CustomerDataWithStatus } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1/models/CustomerDataWithStatus';

export enum PhoneNumberWithTypeTypeEnum {
  MOBILE = 'MOBILE',
  FIXED = 'FIXED',
}

export enum Gender {
  M = 'm',
  F = 'f',
  D = 'd',
}

export const useCustomer = () => {
  const { locale } = useIntl();
  const { softLoggedIn, loggedIn } = useLoginState();
  const customerApi = useCustomerApi();

  const { data: customer, isLoading } = useSWR(
    customerApi && !softLoggedIn && loggedIn ? 'customer' : null,
    () => customerApi!.getCustomer({ ecLocale: locale }),
    { revalidateOnFocus: false },
  );

  return {
    customer,
    isLoading,
  };
};
