import type { SxProps, Theme } from '@mui/system';
import type { CarouselType } from './types';

export function getCarouselWrapperStyles(sx?: SxProps): SxProps {
  return {
    ...sx,
    ...{
      position: 'relative',
    },
  };
}

export const carouselViewportStyles: SxProps = {
  width: '100%',
  height: '100%',
  overflow: 'hidden',

  '> div': {
    height: '100%',
  },
};

export function getCarouselFlexWrapperStyles(gap: CarouselType['gap']): SxProps {
  return {
    display: 'flex',
    gap,
    height: '100%',
  };
}

export const getCarouselChildWrapperStyles =
  (visibleSlides: number | string, gap: number | string): SxProps<Theme> =>
  (theme) => ({
    position: 'relative',
    // caclulate flex-basis without gap
    flex: `0 0 ${
      typeof visibleSlides === 'number'
        ? `calc((100% / ${visibleSlides}) - (((${visibleSlides} - 1) / ${visibleSlides}) * ${
            typeof gap === 'number' ? theme.spacing(gap) : gap
          }))`
        : visibleSlides
    }`,
    minWidth: '0%',
    '& > *': {
      display: 'block',
      width: '100%',
    },
  });
