import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgFuelEfficiency32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" data-name="Ebene 1" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path fill="#262729" d="M19 2a3 3 0 0 1 3 3v1h1a1 1 0 0 1 1 1h.5A1.5 1.5 0 0 1 26 8.5v16a1.5 1.5 0 0 1-3 0V14c0-1-.61-1-1-1v16h1.5a.5.5 0 0 1 0 1h-19a.5.5 0 0 1 0-1H6V5a3 3 0 0 1 3-3Zm0 1H9a2 2 0 0 0-2 1.85V29h14V5a2 2 0 0 0-1.85-2Zm5.5 5H24v1a1 1 0 0 1-1 1h-1v2h.1a1.87 1.87 0 0 1 1.9 2v10.5a.5.5 0 0 0 1 .09V8.5a.5.5 0 0 0-.5-.5M19 4a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1Zm0 1H9v6h10Zm4 2h-1v2h1Z" /></SvgIcon>;
};
const Memo = memo(SvgFuelEfficiency32);
export default Memo;