import { CircularProgress as MuiCircularProgress } from '@mui/material';
import type { CircularProgressProps as MuiCircularProgressProps } from '@mui/material';

export interface CircularProgressProps extends Omit<MuiCircularProgressProps, 'size'> {
  /**
   * Size of loading indicator
   * 's' equals to 24px
   * 'm' equals to 32px
   * 'l' equals to 40px
   * @default 'm'
   */
  size?: 's' | 'm' | 'l';
}

const getSize = (size: CircularProgressProps['size']): number => {
  switch (size) {
    case 's':
      return 24;
    case 'l':
      return 40;
    default:
      return 32;
  }
};

/** Loading indicator in 's', 'm' and 'l' size variants. Based on MUI CircularProgress: https://mui.com/material-ui/api/circular-progress/ */
export const CircularProgress = ({ size, ...props }: CircularProgressProps) => (
  <MuiCircularProgress {...props} size={getSize(size)} aria-label="Content loading" />
);
