import { Link } from '@packages/shared';
import { useTrackCampaign } from '@packages/shared/src/hooks/useTrackCampaign/useTrackCampaign';
import { useRef } from 'react';
import type { SxProps, Theme } from '@mui/material';
import { useAssignLinkTargetBasedOnBotStatus } from '../../hooks/useAssignLinkTargetBasedOnBotStatus';
import type { LinkByTextType } from './types';
import { buildLinkWithArguments } from '../../utils';
import { Icon } from '../Icon/Icon';
import { SoftHyphenInjector } from '../SoftHyphenInjector';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';

/**
 * LinkByText component displays link with Arguments and an optional Icon with a direction
 * */
export const LinkByText = ({
  data,
  sx,
  className,
}: {
  data: LinkByTextType;
  sx?: SxProps<Theme>;
  className?: string;
}) => {
  const {
    field_link: link,
    field_link_target: linkTarget,
    field_direction: direction,
    field_text: text,
    field_icon: icon,
  } = data;
  const tmpLink = buildLinkWithArguments(link, data);
  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();
  const finalLink = assignLinkTarget(tmpLink);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const { trackClick } = useTrackCampaign(data, linkRef, finalLink);
  return (
    <Link
      {...(className && { className })}
      ref={linkRef}
      href={finalLink}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        color: 'text.dark',
        textDecorationColor: (theme) => theme.palette.text.dark,
        '& svg': {
          height: (theme) => theme.spacing(2),
          width: 'auto',
          ...(direction === 'left' && {
            order: '-1',
          }),
        },
        ...sx,
      }}
      onClick={() => trackClick()}
      openInNewTab={!!(linkTarget && linkTarget === '_blank')}
    >
      <SoftHyphenInjector text={text} />
      {icon && <Icon type={icon} />}
    </Link>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.LinkByText]: LinkByText });
