import { useMediaQuery, useTheme } from '@mui/material';

export const useMedia = ({ mobile, tablet, desktop }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.only('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  if (isDesktop) return desktop ?? isDesktop;
  if (isTablet) return tablet ?? isTablet;
  return mobile ?? isMobile;
};
