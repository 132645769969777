import type { FC } from 'react';
import type { PaperProps } from '@mui/material';
import { Paper } from '@mui/material';

export interface FlagProps extends PaperProps {
  /** text displayed in flag */
  text: string;

  /**
   * background and text style of flag
   * @default "neutral"
   */
  type?: 'neutral' | 'secondary' | 'sustainability' | 'sale' | 'contrast';

  /**
   * large flag variant
   * @default false
   */
  large?: boolean;
}

const flagStyle = (type: FlagProps['type']) => {
  switch (type) {
    case 'secondary':
      return { bgcolor: 'secondary.main', color: 'secondary.contrastText' };
    case 'sustainability':
      return { bgcolor: 'sustainability.main', color: 'sustainability.contrastText' };
    case 'sale':
      return { bgcolor: 'sale.main', color: 'sale.contrastText' };
    case 'contrast':
      return { bgcolor: 'grey.dark', color: 'text.light' };
    default:
      return { bgcolor: 'grey.light', color: 'text.dark' };
  }
};

/**
 * Flag component based on https://mui.com/material-ui/react-paper/
 * */
export const Flag: FC<FlagProps> = ({ text, type = 'neutral', large = false, sx, ...rest }) => (
  <Paper
    component="div"
    elevation={0}
    sx={{
      ...flagStyle(type),
      px: 1,
      minHeight: '1rem',
      display: 'inline-flex',
      alignItems: 'center',
      typography: large ? 'body1' : 'body3',
      position: 'relative',
      ...sx,
    }}
    {...rest}
  >
    {text}
  </Paper>
);
