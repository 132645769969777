import { defineMessages } from 'react-intl';

export const unsubscribeMessages = defineMessages({
  legacyHeadline: {
    id: 'forms.newsletter.unsubscribe.headline',
    defaultMessage: 'Schade, willst du dich denn wirklich abmelden?',
  },
  legacyDescription: {
    id: 'forms.newsletter.unsubscribe.description',
    defaultMessage:
      'Du kannst auch selbst bestimmen, wie viele Newsletter du bekommst – oder möchtest du dir wirklich sämtliche Angebote & Rabatte entgehen lassen?',
  },
  submit: {
    id: 'forms.newsletter.unsubscribe.submit',
    defaultMessage: 'Meine Einstellungen speichern',
  },
  submitDisabled: {
    id: 'forms.newsletter.unsubscribe.submit.disabled',
    defaultMessage: 'Bitte fülle zuerst das Formular aus',
  },
  submitButtonTitle: {
    id: 'forms.newsletter.unsubscribe.submitButtontitle',
    defaultMessage: 'Newsletter abmelden',
  },
});

export const frequencyMessages = defineMessages({
  daily: {
    id: 'forms.newsletter.unsubscribe.type.frequency.option.daily',
    defaultMessage: 'Ich möchte weiterhin alle Ausgaben erhalten',
  },
  twicePerWeek: {
    id: 'forms.newsletter.unsubscribe.type.frequency.option.twiceperweek',
    defaultMessage: 'Ich möchte 2 mal pro Woche einen Newsletter erhalten',
  },
  weekly: {
    id: 'forms.newsletter.unsubscribe.type.frequency.option.weekly',
    defaultMessage: 'Ich möchte einen Newsletter pro Woche erhalten',
  },
  biweekly: {
    id: 'forms.newsletter.unsubscribe.type.frequency.option.biweekly',
    defaultMessage: 'Ich möchte 14-tägig informiert werden',
  },
  monthly: {
    id: 'forms.newsletter.unsubscribe.type.frequency.option.monthly',
    defaultMessage: 'Ich möchte einmal pro Monat einen Newsletter erhalten',
  },
  unsubscribe: {
    id: 'forms.newsletter.unsubscribe.type.unsubscribe',
    defaultMessage: 'Ich möchte das Newsletter-Abonnement beenden',
  },
  unsubscribeFlexikonto: {
    id: 'forms.newsletter.unsubscribe.type.unsubscribeFlexikonto',
    defaultMessage: 'Ich möchte mich von Aktionen zum Flexikonto abmelden',
  },
  pause: {
    id: 'forms.newsletter.unsubscribe.type.pause',
    defaultMessage: 'Ich möchte das Newsletter-Abonnement pausieren',
  },

  unsubscribeOttoLascana: {
    id: 'forms.newsletter.unsubscribe.type.unsubscribe.otto-lascana',
    defaultMessage: 'OTTO und LASCANA',
  },
  unsubscribeLascana: {
    id: 'forms.newsletter.unsubscribe.type.unsubscribe.lascana',
    defaultMessage: 'OTTO',
  },
  unsubscribeOtto: {
    id: 'forms.newsletter.unsubscribe.type.unsubscribe.otto',
    defaultMessage: 'LASCANA',
  },
});

export const pauseMessages = defineMessages({
  pause7: {
    id: 'forms.newsletter.unsubscribe.type.pause.option.7',
    defaultMessage: '1 Woche',
  },
  pause14: {
    id: 'forms.newsletter.unsubscribe.type.pause.option.14',
    defaultMessage: '2 Wochen',
  },
  pause21: {
    id: 'forms.newsletter.unsubscribe.type.pause.option.21',
    defaultMessage: '3 Wochen',
  },
  pause30: {
    id: 'forms.newsletter.unsubscribe.type.pause.option.30',
    defaultMessage: '1 Monat',
  },
  // pause60: {
  //   id: 'forms.newsletter.unsubscribe.type.pause.option.60',
  //   defaultMessage: '2 Monate',
  // },
  // pause90: {
  //   id: 'forms.newsletter.unsubscribe.type.pause.option.90',
  //   defaultMessage: '3 Monate',
  // },
});

const successMessages = {
  pause: defineMessages({
    headline: {
      id: 'forms.newsletter.unsubscribe.success.headline.pause',
      defaultMessage: 'Dein Newsletter ist wie gewünscht pausiert',
    },
    description: {
      id: 'forms.newsletter.unsubscribe.success.description.pause',
      defaultMessage: 'Du kannst Deine Einstellungen jederzeit im Abmeldeformluar aktualisieren.',
    },
  }),
  frequency: defineMessages({
    headline: {
      id: 'forms.newsletter.unsubscribe.success.headline.frequency',
      defaultMessage: 'Wir haben deine Einstellungen gespeichert',
    },
    description: {
      id: 'forms.newsletter.unsubscribe.success.description.frequency',
      defaultMessage:
        'Du bekommst unseren Newsletter jetzt nur noch in deiner gewählten Häufigkeit. Du kannst deine Einstellung im Abmeldeformular jederzeit wieder ändern.',
    },
  }),
  unsubscribe: defineMessages({
    headline: {
      id: 'forms.newsletter.unsubscribe.success.headline.unsubscribe',
      defaultMessage: 'Du bist vom Newsletter abgemeldet',
    },
    description: {
      id: 'forms.newsletter.unsubscribe.success.description.unsubscribe',
      defaultMessage:
        'Falls du doch mal Sehnsucht nach uns bekommst, bist du uns natürlich jederzeit wieder willkommen! Melde dich dazu einfach erneut für unseren Newsletter an.',
    },
  }),
};

export const getSuccessMessages = (type: string = '') => {
  switch (type) {
    case 'PAUSE_SUCCESSFUL':
      return successMessages.pause;
    case 'FREQUENCY_SUCCESSFUL':
      return successMessages.frequency;
    case 'UNSUBSCRIBE_SUCCESSFUL':
    default:
      return successMessages.unsubscribe;
  }
};
