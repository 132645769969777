import { useRef } from 'react';
import { Box } from '@packages/shared/src/components/Box/Box';
import { Link } from '@packages/shared/src/components/Link/Link';
import { Tooltip } from '@packages/shared/src/components/Tooltip/Tooltip';
import { ConditionalWrapper } from '@packages/shared/src/components/ConditionalWrapper/ConditionalWrapper';
import { useTrackCampaign } from '@packages/shared/src/hooks/useTrackCampaign/useTrackCampaign';
import { LinkByButton } from '../LinkByButton';
import { LinkByText } from '../LinkByText';
import type { CategoryTeaserItemType } from './types';
import { buildLinkWithArguments } from '../../utils';
import { useGetTooltipContent } from '../../hooks/useGetTooltipContent';
import { ImageTeaser } from '../ImageTeaser';
import { SoftHyphenInjector } from '../SoftHyphenInjector';
import { useAssignLinkTargetBasedOnBotStatus } from '../../hooks/useAssignLinkTargetBasedOnBotStatus';
import { Icon } from '../Icon/Icon';

/**
 * The CategoryTeaserItem component displays a teaser for categories with customizable text and layout.
 * It supports various link functionalities including anchor tags, highlighting, and search filters,
 * as well as tracking for analytics purposes. The component can also handle custom identifiers
 * for advanced behavioral tracking or dynamic content systems. It encapsulates an ImageTeaser
 * component for visual representation.
 *
 * @param {CategoryTeaserItemType} data - The data object containing all necessary fields for rendering the teaser,
 * including link information, custom identifier, and any other required teaser data.
 * @returns A React component structured as a flexible box containing the rendered teaser with the appropriate link
 * and custom identifier attributes.
 */
export const CategoryTeaserItem = ({
  data,
  isTypeOnly = false,
  isLazyLoading = false,
}: {
  data: CategoryTeaserItemType;
  isTypeOnly?: boolean;
  isLazyLoading?: boolean | null;
}) => {
  const {
    // subline
    field_subline_90: subline,

    // link
    field_link: link,
    behavior_paragraph_link_target: linkTarget,
    field_link_anchor: linkAnchor,
    field_link_highlight: linkHighlight,
    field_link_searchfilter: linkSearchFilter,
    field_tracking: linkTracking,

    // Options: left, center
    behavior_paragraph_alignment: layoutAlignment = 'left',

    // Call-to-action
    behavior_paragraph_cta_icon: ctaIcon,
    behavior_paragraph_cta_size: ctaSize = 'small',
    behavior_paragraph_cta_type_and_alignment: ctaTypeAndAlignment,
    behavior_paragraph_layout: ctaLayout,

    // custom identifier e.G. dynamic yield
    behavior_paragraph_custom_id: customId,
    behavior_paragraph_tooltip_activation: tooltipActivation,
    behavior_paragraph_tooltip_positioning: tooltipPositioning,

    // Glycerin Tracking
    behavior_paragraph_campaign_type: glycerinCampaignType,
    behavior_paragraph_campaignid: glycerinCampaginId,
    behavior_paragraph_category: glycerinCategory,
    behavior_paragraph_detail: glycerinDetail,
    behavior_paragraph_name: glycerinName,

    // promotion item
    promotion_item: tooltipDataPromotion,
    content_snippet_bundled: tooltipDataContentSnippet,
  } = data;

  const tooltipContent = useGetTooltipContent(tooltipDataPromotion, tooltipDataContentSnippet);

  const tmpLink: string = link
    ? buildLinkWithArguments(link, {
        field_link_anchor: linkAnchor,
        field_link_highlight: linkHighlight,
        field_link_searchfilter: linkSearchFilter,
        field_tracking: linkTracking,
      })
    : '';
  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();
  const finalLink = assignLinkTarget(tmpLink);
  const teaserRef = useRef<HTMLSpanElement>(null);
  const { trackClick } = useTrackCampaign(data, teaserRef, finalLink);
  const showButton =
    !isTypeOnly &&
    finalLink !== '' &&
    ctaTypeAndAlignment &&
    ['button-below-image', 'button-on-image'].includes(ctaTypeAndAlignment);
  const showTextLink =
    !isTypeOnly &&
    finalLink !== '' &&
    ctaTypeAndAlignment &&
    ['textlink-below-image', 'textlink-on-image'].includes(ctaTypeAndAlignment);
  const ctaAlignment =
    ctaTypeAndAlignment && ['textlink-on-image', 'button-on-image'].includes(ctaTypeAndAlignment)
      ? 'on-image'
      : 'below-image';

  return (
    <Box
      className="category-teaser-item"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        position: 'relative',
        'a, img, span': {
          '&:not(.clickable-element, [class^="tooltip"], [class^="MuiButton"])': {
            display: 'block',
            width: '100%',
            lineHeight: 'normal',
          },
          '.clickable-element': {
            mt: 1,
          },
        },
      }}
      data-dy={customId}
    >
      {!isTypeOnly && (
        <ConditionalWrapper
          condition={!!(tooltipContent && !!tooltipActivation)}
          wrapper={(ch) => (
            <Tooltip variant="default" content={tooltipContent} positioning={tooltipPositioning}>
              {ch}
            </Tooltip>
          )}
        >
          <ImageTeaser
            data={data}
            link={finalLink}
            linkTarget={linkTarget}
            isLazyLoading={isLazyLoading}
          />
        </ConditionalWrapper>
      )}
      {subline && (
        <Box
          sx={{
            width: '100%',
            color: 'text.dark',
            fontWeight: 600,
            ...(!isTypeOnly && { mt: '0.125rem' }),
            ...(isTypeOnly && { textAlign: 'center' }),
            ...(ctaAlignment === 'on-image' && {
              position: 'absolute',
              bottom: '1rem',
              ...(layoutAlignment === 'center'
                ? {
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }
                : {
                    left: '1rem',
                  }),
            }),
          }}
          component="span"
          className="subline"
        >
          {showButton && (
            <LinkByButton
              className="clickable-element"
              data={{
                type: 'link_by_button',
                field_link: finalLink,
                field_link_target: linkTarget,
                field_text: subline,
                behavior_paragraph_layout: ctaLayout,
                behavior_paragraph_size: ctaSize,
                behavior_paragraph_campaign_type: glycerinCampaignType,
                behavior_paragraph_campaignid: glycerinCampaginId,
                behavior_paragraph_category: glycerinCategory,
                behavior_paragraph_detail: glycerinDetail,
                behavior_paragraph_name: glycerinName,
              }}
              icon={ctaIcon ? 'ArrowRight' : undefined}
            />
          )}

          {showTextLink && (
            <LinkByText
              className="clickable-element"
              data={{
                type: 'link_by_text',
                field_link: finalLink,
                field_link_target: linkTarget,
                field_text: subline,
                field_icon: ctaIcon ? 'ArrowRight' : undefined,
                behavior_paragraph_campaign_type: glycerinCampaignType,
                behavior_paragraph_campaignid: glycerinCampaginId,
                behavior_paragraph_category: glycerinCategory,
                behavior_paragraph_detail: glycerinDetail,
                behavior_paragraph_name: glycerinName,
              }}
            />
          )}

          {!showButton && !showTextLink && (
            <ConditionalWrapper
              condition={!!link}
              wrapper={(ch) => (
                <Link
                  href={finalLink}
                  onClick={() => trackClick()}
                  {...(linkTarget && { target: linkTarget })}
                  noLinkStyle
                >
                  {ch}
                </Link>
              )}
            >
              <ConditionalWrapper
                condition={!!(isTypeOnly && tooltipContent && !!tooltipActivation)}
                wrapper={(chInner) => (
                  <Tooltip
                    variant="inline"
                    content={tooltipContent}
                    positioning={tooltipPositioning}
                  >
                    {chInner}
                  </Tooltip>
                )}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& svg': {
                      height: (theme) => theme.spacing(2),
                    },
                  }}
                >
                  <SoftHyphenInjector text={subline} />
                  {ctaIcon && <Icon type="ArrowRight" />}
                </Box>
              </ConditionalWrapper>
            </ConditionalWrapper>
          )}
        </Box>
      )}
    </Box>
  );
};
