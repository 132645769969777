/* istanbul ignore file */
import type { GenericTrackingEvent } from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { useEffect } from 'react';

type GtmEvent = {
  sourceCategoryId?: string | number;
  rdeTypeAbbr: string;
  outputElementNo: number;
};

type TrackingProps = {
  rdeTypeAbbr?: string;
  elementIndex?: number;
  sourceCategoryId: string | number;
  trackingCondition: boolean;
};

export const useBrandsRecoTracking = ({
  rdeTypeAbbr,
  elementIndex,
  sourceCategoryId,
  trackingCondition,
}: TrackingProps) => {
  const dispatchGTMEvent = useTracking();

  useEffect(() => {
    if (trackingCondition && elementIndex && rdeTypeAbbr) {
      dispatchGTMEvent<GenericTrackingEvent<GtmEvent>>({
        event: 'ga_event',
        eventAction: 'view',
        eventCategory: 'elementImpression',
        eventLabel: 'brandRecoImpression',
        eventValue: {
          rdeTypeAbbr,
          outputElementNo: (elementIndex ?? 0) + 1,
          sourceCategoryId,
        },
      });
    }
  }, [trackingCondition, elementIndex, dispatchGTMEvent, rdeTypeAbbr, sourceCategoryId]);
};
