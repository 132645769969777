import DOMPurify from '@packages/config/utils/dompurify';
import { Box, Button, Dialog } from '@packages/shared';
import { useState } from 'react';
import { useTheme } from '@mui/material';
import { SoftHyphenInjector } from '../SoftHyphenInjector';
import { ComponentTypes } from '../../../interfaces';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import type { ModalByButtonType } from './types';

/**
 * This component generates a button element that opens a modal layer when clicked.
 *
 * @param data Object contains data
 * @returns JSX.Element to be placed in PageLayout
 */
export const ModalByButton = ({ data }: { data: ModalByButtonType }) => {
  const {
    field_text: buttonLinkText,
    field_headline: modalHeadline = '',
    field_html_editor: modalContent,
    behavior_paragraph_size: behaviorSize,
    behavior_paragraph_layout: behaviorLayout,
  } = data;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { palette } = useTheme();

  return (
    <>
      <Button
        onClick={() => {
          setIsDialogOpen(true);
        }}
        {...(behaviorSize && {
          size: behaviorSize,
        })}
        {...(behaviorLayout &&
          // runtime-check if value is available in theme palette
          Object.keys(palette).includes(behaviorLayout) && { color: behaviorLayout })}
      >
        <SoftHyphenInjector text={buttonLinkText} />
      </Button>
      <Dialog
        open={isDialogOpen}
        handleClose={() => {
          setIsDialogOpen(false);
        }}
        title={modalHeadline}
        dialogContent={
          <Box
            component="div"
            sx={{ p: 2 }}
            // eslint-disable-next-line react/no-danger,@typescript-eslint/ban-ts-comment
            // @ts-ignore
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(modalContent) }}
          />
        }
      />
    </>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.ButtonModal]: ModalByButton });
