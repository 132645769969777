import { useCookieConsent } from '@packages/shared/src/hooks/useCookieConsent/useCookieConsent';
import { useEffect } from 'react';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';

/**
 * This component represents external JavaScript resources to be used on content pages.
 * This component delivers liveshopping content.
 *
 * @param showId String contains show identifier
 * @returns JSX.Element to be placed in PageLayout
 */
export const Liveshopping = ({ showId }: { showId: string }) => {
  const isAllowed: boolean = useCookieConsent('C0004');
  const dispatchGTMEvent = useTracking();

  useEffect(() => {
    if (isAllowed) {
      dispatchGTMEvent({
        event: 'renderLiveshoppingScript',
        liveshoppingShowId: showId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchGTMEvent, isAllowed]);

  return <div id={showId} />;
};
