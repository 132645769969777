/* istanbul ignore file */
import { useEffect } from 'react';
import type {
  GTMEventGlycerinDisplayRecommendation,
  GenericTrackingEvent,
} from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type { FragmentType } from '@packages/gql/generated/shopping';
import type { CategoryRecommendationFragmentFragmentDoc } from '@packages/gql/generated/shopping/CategoryRecommendationFragmentFragmentDoc';
import { getCategoryGlycerinTiles } from '../../helpers/getCategoryGlycerinTiles';
import type { RdeType } from '../../types';

type GtmEvent = {
  sourceCategoryId?: string | number;
  rdeTypeAbbr: string;
  outputElementNo: number;
};

type TrackingProps = {
  elementIndex?: number;
  rdeTypeAbbr?: string;
  rdeType: RdeType;
  recommendations?: FragmentType<typeof CategoryRecommendationFragmentFragmentDoc>[];
  sourceCategoryId: string | number;
  title: string;
  trackingCondition: boolean;
};

export const useCategoriesRecoTracking = ({
  elementIndex,
  rdeType,
  rdeTypeAbbr,
  recommendations,
  sourceCategoryId,
  title,
  trackingCondition,
}: TrackingProps) => {
  const dispatchGTMEvent = useTracking();

  useEffect(() => {
    if (trackingCondition && rdeTypeAbbr && recommendations !== undefined) {
      dispatchGTMEvent<GenericTrackingEvent<GtmEvent>>({
        event: 'ga_event',
        eventAction: 'view',
        eventCategory: 'elementImpression',
        eventLabel: 'categoryRecoImpression',
        eventValue: {
          rdeTypeAbbr,
          outputElementNo: (elementIndex ?? 0) + 1,
          sourceCategoryId,
        },
      });

      // glycerin seen tracking
      dispatchGTMEvent<GTMEventGlycerinDisplayRecommendation>({
        event: 'DisplayRecommendation',
        DisplayRecommendationData: {
          name: title === '' ? rdeType : title,
          // TODO: add placement as prop in trackingProps -> https://empiriecom.atlassian.net/browse/INFORM-2616
          // placement: GlycerinPlacement.CONTENT,
          recoId: rdeTypeAbbr,
          recoType: 'carousel',
          strategy: rdeType,
          tiles: getCategoryGlycerinTiles(recommendations),
          totalSize: recommendations.length,
        },
      });
    }
  }, [
    dispatchGTMEvent,
    elementIndex,
    rdeType,
    rdeTypeAbbr,
    recommendations,
    sourceCategoryId,
    title,
    trackingCondition,
  ]);
};
