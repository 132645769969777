import { Skeleton, SlotBox } from '@packages/shared';
import { RecoBrandCardSkeleton } from '../RecoBrandCardSkeleton';
import { RecoCategoryCardSkeleton } from '../RecoCategoryCardSkeleton';
import { RecoProductCardSkeleton } from '../RecoProductCardSkeleton';

type RecoCarouseSkeletonProps = {
  /**
   * The type of skeleton cards to display. Default is `PRODUCT`.
   * @default "PRODUCT"
   */
  type?: 'CAT' | 'PRODUCT' | 'BRAND';
  /**
   * If `true`, the skeleton for title will not be displayed
   * @default false
   */
  hasNoTitle?: boolean;
};

/**
 * This component can be used to display a skeleton for the recommendations carousel.
 */
export const RecoCarouselSkeleton = ({
  hasNoTitle = false,
  type = 'PRODUCT',
}: RecoCarouseSkeletonProps) => {
  let skeletonToRender = <RecoProductCardSkeleton />;

  if (type === 'CAT') {
    skeletonToRender = <RecoCategoryCardSkeleton />;
  } else if (type === 'BRAND') {
    skeletonToRender = <RecoBrandCardSkeleton />;
  }
  return (
    <div>
      {!hasNoTitle && <Skeleton variant="text" width="35%" height={60} />}

      <SlotBox sx={{ p: 2, pb: 2 }}>{skeletonToRender}</SlotBox>
    </div>
  );
};
