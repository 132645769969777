import config from '@packages/config';
import type { SxProps, Theme } from '@mui/material/styles';
import { sizeStyle, transformStyle } from './styles';

const margin = '0.813rem';
const marginLg = '1.25rem';

export const styleHelperType = (styleType: string, styleScalingFactor?: number): SxProps<Theme> => {
  switch (styleType) {
    case 'left':
      return {
        top: '50%',
        transformOrigin: 'left',
        left: margin,
        ...sizeStyle.base,
        ...transformStyle(styleScalingFactor, true).base,
        '@container (min-width: 900px)': {
          '&': {
            left: marginLg,
            ...sizeStyle.lg,
            ...transformStyle(styleScalingFactor, true).lg,
          },
        },
      };
    case 'top-left':
      return {
        transformOrigin: 'left top',
        left: margin,
        top: margin,
        ...sizeStyle.base,
        ...transformStyle(styleScalingFactor).base,
        '@container (min-width: 900px)': {
          '&': {
            left: marginLg,
            top: marginLg,
            ...sizeStyle.lg,
            ...transformStyle(styleScalingFactor).lg,
          },
        },
      };
    case 'top-right':
      return {
        transformOrigin: 'right top',
        right: margin,
        top: margin,
        ...sizeStyle.base,
        ...transformStyle(styleScalingFactor).base,
        '@container (min-width: 900px)': {
          '&': {
            right: marginLg,
            top: marginLg,
            ...sizeStyle.lg,
            ...transformStyle(styleScalingFactor).lg,
          },
        },
      };
    case 'bottom-left':
      return {
        transformOrigin: 'left bottom',
        left: margin,
        bottom: margin,
        ...sizeStyle.base,
        ...transformStyle(styleScalingFactor).base,
        '@container (min-width: 900px)': {
          '&': {
            left: marginLg,
            bottom: marginLg,
            ...sizeStyle.lg,
            ...transformStyle(styleScalingFactor).lg,
          },
        },
      };
    case 'bottom-right':
      return {
        transformOrigin: 'right bottom',
        right: margin,
        bottom: margin,
        ...sizeStyle.base,
        ...transformStyle(styleScalingFactor).base,
        '@container (min-width: 900px)': {
          '&': {
            right: marginLg,
            bottom: marginLg,
            ...sizeStyle.lg,
            ...transformStyle(styleScalingFactor).lg,
          },
        },
      };
    case 'right':
    default:
      return {
        ...{
          top: '50%',
          transformOrigin: 'right',
          right: margin,
          ...sizeStyle.base,
          ...transformStyle(styleScalingFactor, true).base,
          '@container (min-width: 900px)': {
            '&': {
              right: marginLg,
              ...sizeStyle.lg,
              ...transformStyle(styleScalingFactor, true).lg,
            },
          },
        },
      };
  }
};

export const containerStyle = ({
  styleType = 'left',
  styleBackgroundColor,
  styleScalingFactor = 1,
}: {
  styleBackgroundColor?: string;
  styleScalingFactor?: number;
  styleType?: string;
}): SxProps<Theme> => ({
  '&': config.staticContent.cmsFontToTheme,
  position: 'absolute',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 2,
  overflow: 'hidden',
  textAlign: 'center',
  textDecoration: 'none',
  '> a': {
    textDecoration: 'none',
  },
  backgroundColor: styleBackgroundColor || 'primary.dark',
  borderRadius: '50%',
  ...styleHelperType(styleType, styleScalingFactor),
});
