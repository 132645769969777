import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { CategoryRecommendationFragmentFragmentDoc } from '@packages/gql/generated/shopping/CategoryRecommendationFragmentFragmentDoc';
import type { GlycerinTile } from '@packages/tracking/src/types/types';
import { GlycerinTileType } from '@packages/tracking/src/types/enums';

/**
 * This function is used to retrieve the Glycerin tiles from the recommendations.
 * @param recommendations - The recommendations to filter and map to Glycerin tiles.
 * @returns GlycerinTile[] - An array of GlycerinTile objects.
 */
export const getCategoryGlycerinTiles = (
  recommendationsData: FragmentType<typeof CategoryRecommendationFragmentFragmentDoc>[],
): GlycerinTile[] => {
  const recommendations = getFragmentData(
    CategoryRecommendationFragmentFragmentDoc,
    recommendationsData,
  );

  return recommendations.map((_, index) => {
    const tileType: GlycerinTileType = GlycerinTileType.CATEGORY;

    return {
      tileType,
      listPosition: index + 1,
    };
  });
};
