import type { FC } from 'react';
// INFO: temporary path alias replaced with relative import because jest resolver problems
import { useMedia } from '../../../hooks/useMedia/useMedia';
import { Carousel } from '../Carousel/Carousel';
import type { CarouselType } from '../types';

/**
 * ResponsiveCarousel Component based on https://www.embla-carousel.com
 */
export const ResponsiveCarousel: FC<
  CarouselType & {
    mobile?: Omit<CarouselType, 'children'>;
    tablet?: Omit<CarouselType, 'children'>;
    desktop?: Omit<CarouselType, 'children'>;
  }
> = (props) => {
  const { mobile, tablet, desktop, children } = props;
  const settings = useMedia({
    mobile: mobile || { visibleSlides: 2 },
    tablet: tablet || { visibleSlides: 3 },
    desktop: desktop || { visibleSlides: 5 },
  });

  return (
    <Carousel {...props} {...settings}>
      {children}
    </Carousel>
  );
};
