import type { SxProps } from '@mui/material';
import { Box, CircularProgress } from '@packages/shared';

/**
 * This component displays Loader
 * @param sx allow to define optional MUI System sx prop
 * @returns JSX.Element
 */
export const CmsLoader = ({ sx }: { sx?: SxProps }): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      ...sx,
    }}
  >
    <CircularProgress size="m" />
  </Box>
);
