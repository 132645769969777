import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWetGrip32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" data-name="Ebene 1" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path fill="#262729" d="M15.54 26.54a.5.5 0 0 1 .69-.14.51.51 0 0 1 .14.7l-1.67 2.49a.49.49 0 0 1-.69.14.5.5 0 0 1-.14-.69Zm2.77-4.16a.5.5 0 1 1 .83.56l-1.66 2.49a.5.5 0 1 1-.83-.55Zm-10 0a.5.5 0 1 1 .83.56l-1.66 2.49a.5.5 0 0 1-.83-.55Zm5 0a.5.5 0 1 1 .83.56l-1.66 2.49a.5.5 0 0 1-.83-.55ZM19 3a6 6 0 0 1 5.92 5h.58a5.5 5.5 0 0 1 0 11h-3.73l-1.52 2.27a.49.49 0 0 1-.61.19l-.08-.05a.5.5 0 0 1-.14-.69L20.56 19h-3.79l-1.52 2.27a.49.49 0 0 1-.61.19l-.08-.05a.5.5 0 0 1-.14-.69L15.56 19h-3.79l-1.52 2.27a.49.49 0 0 1-.61.19l-.08-.05a.5.5 0 0 1-.14-.69L10.56 19H6.5a5.5 5.5 0 0 1 0-11h.63A4 4 0 0 1 11 5a4 4 0 0 1 2.75 1.09A6 6 0 0 1 19 3m0 1a5 5 0 0 0-4.38 2.58 1 1 0 0 1-1.56.24 3 3 0 0 0-5 1.43 1 1 0 0 1-.82.75H6.5a4.5 4.5 0 0 0-.21 9H25.5a4.5 4.5 0 0 0 .21-9h-.79a1 1 0 0 1-1-.83A5 5 0 0 0 19 4" /></SvgIcon>;
};
const Memo = memo(SvgWetGrip32);
export default Memo;