import Head from 'next/head';
import { useEffect } from 'react';

declare global {
  interface Window {
    [key: string]: number;
  }
}

export const Userwerk = ({
  shopTag = 'baude-newsletter',
}: {
  shopTag?: string;
}): JSX.Element | null => {
  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention
    const _uw: any = window?._uw || {};
    _uw.order_id = ''; // your order ID
    _uw.order_total = ''; // the total amount of the order
    _uw.order_items_categories = []; // product categories ordered
    _uw.order_voucher_code = []; // redeemed voucher codes
    _uw.first_name = ''; // customers first name
    _uw.last_name = ''; // customers last name
    _uw.title = ''; // customers title / gender - for possible other values get in touch with us if necessary
    _uw.postal_code = ''; // customers postal code
    _uw.city = ''; // customers city
    _uw.street = ''; // customers street
    _uw.house_number = ''; // customers house number
    _uw.email = ''; // customers email address
    _uw.birthday = ''; // customers birthday
    _uw.combined_phone_number = ''; // customers phone number
    _uw.country = ''; // customers country
    // eslint-disable-next-line no-underscore-dangle
    window._uw = _uw;
  }, []);

  return (
    <div key="userwerk-script">
      <Head>
        <link rel="dns-prefetch" href={`https://${shopTag}.int.userwerk.com/`} />
        <link rel="preconnect" href={`https://${shopTag}.int.userwerk.com/`} />
        <script src={`https://${shopTag}.int.userwerk.com/uw.js`} type="text/javascript" async />
      </Head>
      <div id="userwerk" />
    </div>
  );
};
