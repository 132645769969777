import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Checkmark32 } from '@packages/themes/icons';
import type { ReactNode, FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'search.inspiring.storefront.teaser.title',
    defaultMessage: 'Inspirierende Suche',
  },
  label: {
    id: 'search.inspiring.storefront.teaser.title',
    defaultMessage: 'Inspiriende suche für alle',
  },
  description: {
    id: 'search.inspiring.storefront.teaser.description',
    defaultMessage:
      'Gib ganze Sätze ein und erhalte passende Vorschläge in verschiedenen Kategorien. Finde einfach das, was dein Herz höher schlagen lässt!',
  },
  bullet1: {
    id: 'search.inspiring.storefront.teaser.bullet1',
    defaultMessage: 'Vorschläge zu deiner Suche',
  },
  bullet2: {
    id: 'search.inspiring.storefront.teaser.bullet2',
    defaultMessage: 'Inspiration für dich',
  },
  bullet3: {
    id: 'search.inspiring.storefront.teaser.bullet3',
    defaultMessage: 'Formuliere ganze Sätze',
  },
});
type InspireSearchTeaserProps = {
  SearchField: ReactNode;
};

const InspiringTeaserBullets = ({ children }: { children: string }) => (
  <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="center">
    <Checkmark32 sx={{ fontSize: '1.5rem' }} />
    <Typography variant="body2">{children}</Typography>
  </Stack>
);

/**
 * InspiringSearchTeaser component handles the display of the Inspiring Search feature (search buddy) on the store front,
providing users with suggestions and searched content based on their input and context.
 * */
export const InspiringSearchTeaser: FC<InspireSearchTeaserProps> = ({ SearchField }) => {
  const { formatMessage } = useIntl();

  return (
    <Stack
      aria-label={formatMessage(messages.label)}
      padding={1.5}
      sx={{
        backgroundImage: 'url(/search-assets/inspiring-search-background.svg)',
        backgroundSize: '100% 100%',
        borderRadius: '3px',
      }}
    >
      <Stack
        spacing={{ sm: 2.875, md: 3.25 }}
        padding={1.5}
        sx={{
          backgroundColor: 'grey.light',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack
          flex={1}
          spacing={{ sm: 3.5, md: 2.5 }}
          sx={{ maxWidth: '21.813rem', justifyContent: 'center' }}
        >
          <Typography textAlign="center" variant="h2">
            {formatMessage(messages.title)}
          </Typography>
          <Typography textAlign="center" variant="body2" fontWeight={600}>
            {formatMessage(messages.description)}
          </Typography>
          {SearchField}
        </Stack>
        <Stack
          direction={{ sm: 'column', md: 'row' }}
          spacing={{ sm: 1.75, md: 5 }}
          justifyContent="center"
          alignItems="flex-start"
        >
          <InspiringTeaserBullets>{formatMessage(messages.bullet1)}</InspiringTeaserBullets>
          <InspiringTeaserBullets>{formatMessage(messages.bullet2)}</InspiringTeaserBullets>
          <InspiringTeaserBullets>{formatMessage(messages.bullet3)}</InspiringTeaserBullets>
        </Stack>
      </Stack>
    </Stack>
  );
};
