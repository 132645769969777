import type { DialogProps } from '@packages/shared';
import { ErrorBoundary, Dialog } from '@packages/shared';
import { useIntl } from 'react-intl';
import { Suspense } from 'react';

import { installmentCalulatorMessages } from '../messages';
import type { InstallmentCalculatorProps } from '../InstallmentCalculator';
import { InstallmentCalculatorErrorFallback } from '../../utils/InstallmentCalculatorErrorFallback';
import { InstallmentCalculatorSuspenseFallback } from '../../utils/InstallmentCalculatorSuspenseFallback';
import { LazyCalculator } from '../../utils/LazyCalculator';

type InstallmentCalculatorDialogProps = Omit<DialogProps, 'title' | 'dialogContent'> &
  Omit<InstallmentCalculatorProps, 'layout'>;

export const InstallmentCalculatorDialog = ({
  basePrice,
  hasEditablePrice,
  source,
  ...dialogProps
}: InstallmentCalculatorDialogProps) => {
  const { formatMessage } = useIntl();
  return (
    <Dialog
      size="xl"
      sx={{
        '.MuiDialogContent-root': {
          bgcolor: 'common.white',
        },
      }}
      {...dialogProps}
      dialogContent={
        <ErrorBoundary
          fallback={(reset) => <InstallmentCalculatorErrorFallback onCloseHandler={reset} />}
        >
          <Suspense fallback={<InstallmentCalculatorSuspenseFallback calculatorLayout="Columns" />}>
            <LazyCalculator
              basePrice={basePrice}
              hasEditablePrice={hasEditablePrice}
              source={source}
              layout="Columns"
            />
          </Suspense>
        </ErrorBoundary>
      }
      title={formatMessage(installmentCalulatorMessages.Headline)}
    />
  );
};
