import type { SxProps } from '@mui/system';
import React from 'react';
import type { MuiIconButtonProps } from '../../Button';
import { MuiIconButton } from '../../Button';

const buttonDefaultStyles: SxProps = {
  width: '40px',
  height: '40px',
  backgroundColor: 'common.white',
  position: 'absolute',
  transform: 'translateY(-50%)',
  top: '50%',
  boxShadow:
    '0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12),0 6px 10px 0 rgba(0, 0, 0, 0.14)',
  touchAction: 'manipulation',
  '& svg': {
    fill: 'text.dark',
  },
  ':hover': {
    opacity: 0.7,

    backgroundColor: 'common.white',
  },
};

export const ArrowButton = ({ children, sx, ...rest }: MuiIconButtonProps) => (
  <MuiIconButton sx={{ ...buttonDefaultStyles, ...sx }} {...rest}>
    {children}
  </MuiIconButton>
);
