import type { EmblaPluginType } from 'embla-carousel';
import EmblaAutoplay from 'embla-carousel-autoplay';
import type { CarouselType } from '../types';

export function getEmblaPlugins(autoplay: CarouselType['autoplay']): EmblaPluginType[] {
  const emblaPlugins: EmblaPluginType[] = [];

  // Embla Autoplay Settings
  if (autoplay) {
    const defaultOptions = {
      delay: 3000,
      stopOnInteraction: false,
      stopOnMouseEnter: true,
    };

    const options =
      typeof autoplay === 'object' ? { ...defaultOptions, ...autoplay } : defaultOptions;
    // add to embla plugin array
    emblaPlugins.push(EmblaAutoplay(options));
  }

  return emblaPlugins;
}
