import type { FC } from 'react';
import { useState } from 'react';
import { Paper } from '@mui/material';
import type { SxProps, SvgIconProps } from '@mui/material';
import {
  FuelEfficiency32,
  WetGrip32,
  ExternalRollingNoise32,
  EnergyEfficiency,
  EnergyEfficiencyOld,
} from '@packages/themes/icons';
import { Image } from '../Image';
import { Typography } from '../Typography';
import { Dialog } from '../Dialog';
import { Box } from '../Box';

export type EfficiencyFlagProps = {
  /** flag type */
  type:
    | 'fuelEfficiency'
    | 'wetGrip'
    | 'externalRollingNoise'
    | 'energyEfficiency'
    | 'energyEfficiencyOld';
  /** text displayed in flag */
  text: string;
  /** bgcolor of energy efficiency flags */
  labelColor?:
    | 'darkgreen'
    | 'mediumgreen'
    | 'lightgreen'
    | 'yellow'
    | 'lightorange'
    | 'darkorange'
    | 'red';
  /** overlay when flag is clicked */
  overlay?: {
    title: string;
    imgUrl: string;
  };
  /**
   * large flag variant
   * @deprecated use `variant="large"` instead
   *
   * @default false
   */
  large?: boolean;
  /** Which display variant to use
   * - 'large' for a large flag
   * - 'normal' for a small flag with a large font size, matching the {@link ProductPrice} font size for legal requirements
   * - 'small' for a small flag with a small font size, matching the `small` variant of the {@link ProductPrice}
   *
   * @default 'normal'
   */
  variant?: 'large' | 'normal' | 'small';
  /** MUI Sx Prop */
  sx?: SxProps;

  /** Optional label for efficiency-flag  */
  label?: string;
  /**
   * This callback function is called after the EfficiencyFlag has been clicked.
   */
  onClickHandler?: () => void;
  /**
   * This callback function is called after the dialog close button has been clicked.
   */
  onCloseHandler?: () => void;
};

const labelColorsMap = {
  darkgreen: '#008C3E',
  mediumgreen: '#50A733',
  lightgreen: '#CDD100',
  yellow: '#FFEA00',
  lightorange: '#F8BA00',
  darkorange: '#EC6707',
  red: '#DB0E15',
};

const svgIconsMap = {
  fuelEfficiency: FuelEfficiency32,
  wetGrip: WetGrip32,
  externalRollingNoise: ExternalRollingNoise32,
  energyEfficiency: EnergyEfficiency,
  energyEfficiencyOld: EnergyEfficiencyOld,
};

const Icon = ({
  type,
  ...props
}: {
  type: EfficiencyFlagProps['type'];
} & SvgIconProps) => {
  const ActualIcon = svgIconsMap[type];

  return <ActualIcon {...props} />;
};

/**
 * EfficiencyFlag component with overlay to display energy label
 * */
export const EfficiencyFlag: FC<EfficiencyFlagProps> = ({
  type,
  text,
  labelColor,
  overlay,
  large = false,
  variant = large ? 'large' : 'normal',
  sx,
  label,
  onClickHandler,
  onCloseHandler,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);

  const openOverlay = () => {
    onClickHandler?.();
    setShowOverlay(true);
  };
  const closeOverlay = () => {
    onCloseHandler?.();
    setShowOverlay(false);
  };

  const overlayContent = (
    <Typography
      component="div"
      sx={{
        position: 'relative',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 600,
        '> img': {
          objectFit: 'contain',
        },
      }}
    >
      {overlay?.imgUrl && <Image src={overlay.imgUrl} alt={overlay.title} fill unoptimized />}
    </Typography>
  );

  return (
    <>
      <Box
        tabIndex={0}
        sx={{ display: 'flex', alignItems: 'center' }}
        component={overlay ? 'a' : 'div'}
        title={overlay ? overlay.title : undefined}
        onClick={overlay ? openOverlay : undefined}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            openOverlay();
          }
        }}
      >
        <Paper
          variant="outlined"
          sx={{
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
            borderColor: 'text.dark',
            bgcolor: 'transparent',
            textDecoration: 'none',
            cursor: 'pointer',
            ...(type.includes('energyEfficiency')
              ? {
                  border: 'none',
                }
              : {
                  px: variant === 'large' ? 1 : 0.5,
                  py: 0.25,
                }),
            ...sx,
          }}
        >
          <Icon
            type={type}
            sx={{
              fill: 'inherit',
              color: labelColor ? labelColorsMap[labelColor] : undefined,
              fontSize: variant === 'large' ? 32 : 16,
              ...(type.includes('energyEfficiency') && {
                fontSize: variant === 'large' ? 55 : 45,
                width: variant === 'large' ? 61 : 35,
                height: variant === 'large' ? 35 : 20,
              }),
            }}
          />

          <Typography
            component="p"
            variant={variant === 'small' ? 'body1' : 'h4'} // NOTE: small price uses h6 according to design system, but current implementation of flag is geared towards body1, which is a bit larger
            sx={{
              ml: { large: 1, normal: 0.2, small: 0.4 }[variant],
              fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
              '> sup': {
                fontSize: variant === 'large' ? 12 : 8,
              },
              ...(type.includes('energyEfficiency') && {
                position: 'absolute',
                color: 'text.light',
                fontWeight: 700,
              }),
              ...(type === 'energyEfficiency' && {
                ml: { large: 2.5, normal: 1.25, small: 1.5 }[variant],
              }),
              ...(type === 'energyEfficiencyOld' &&
                text.includes('+') && {
                  top: 0,
                  lineHeight: { large: '28px', normal: '0.85rem', small: '15px' }[variant],
                }),
              ...(!type.startsWith('energyEfficiency') &&
                variant === 'normal' && {
                  lineHeight: '1.25rem',
                }),
            }}
          >
            {text.includes('+') ? (
              <>
                {text.substring(0, 1)}
                <sup>{text.substring(1)}</sup>
              </>
            ) : (
              text
            )}
          </Typography>
        </Paper>

        {label && (
          <Typography component="span" variant="body2" sx={{ ml: 1, cursor: 'pointer' }}>
            {label}
          </Typography>
        )}
      </Box>
      {overlay && (
        <Dialog
          open={showOverlay}
          handleClose={closeOverlay}
          title={overlay.title}
          dialogContent={overlayContent}
        />
      )}
    </>
  );
};
