import type { GetClickTrackingBrandsCategoriesURLExtensionProps } from '../../types';

export const getClickTrackingBrandsCategoriesURLExtension = ({
  cid,
  position,
  pageTemplate,
  recoLogic,
  rdeType,
  itemsToLoad,
  name,
}: GetClickTrackingBrandsCategoriesURLExtensionProps) => {
  // To support click tracking the URL has to be extended with the needed parameters
  const urlExtension = `#ref=reco&lmPromo=la,1,hk,${pageTemplate},fl,${rdeType}_${itemsToLoad}_${position}_${cid}_${name}_${recoLogic}`;

  return urlExtension;
};
