import { PrudsysCategoryToBrandsDocument } from '@packages/gql/generated/shopping/PrudsysCategoryToBrandsDocument';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'urql';
import { useRecoElement } from '../../../hooks/useRecoElement';
import type { BrandsRecoSliderProps, RdeType, RequestParams } from '../../../types';
import { RecoBrandCard } from '../../RecoBrandCard';
import { useBrandsRecoTracking } from '../../RecoBrandCard/useBrandsRecoTracking';
import { RecoCarousel } from '../../RecoCarousel';
import { RecoCarouselSkeleton } from '../../RecoCarouselSkeleton';

export type PrudsysCategoryToBrandsProps = Pick<RequestParams, 'categoryId'> &
  Omit<BrandsRecoSliderProps, 'preferTitle'>;

/* GraphQL */ `
  query PrudsysCategoryToBrands($categoryId: String!, $locale: String!) {
    prudsysCategoryToBrands(categoryId: $categoryId, locale: $locale) {
      type
      recommendations {
        ...BrandRecommendationFragment
      }
    }
  }
`;

const RDE_TYPE: RdeType = 'prudsysCategoryToBrands';

/**
 * SimilarProductsRecoSlider component description displayed in storybook
 * */
export const PrudsysCategoryToBrandsRecoSlider: FC<PrudsysCategoryToBrandsProps> = ({
  categoryId,
  elementIndex = 0,
  fetchOnThreshold = false,
  pageTemplate,
  sourceCategoryId,
  title,
}) => {
  const { isInThreshold, isInView, recoEl } = useRecoElement();
  const { locale } = useIntl();

  const [{ data, error }] = useQuery({
    query: PrudsysCategoryToBrandsDocument,
    variables: {
      categoryId,
      locale: locale!,
    },
    context: useMemo(() => ({ suspense: false }), []),
    pause: fetchOnThreshold ? !isInThreshold : false,
    requestPolicy: 'network-only',
  });

  const recommendations = data ? data.prudsysCategoryToBrands.recommendations : undefined;

  const rdeTypeAbbr = data?.prudsysCategoryToBrands.type;

  useBrandsRecoTracking({
    rdeTypeAbbr,
    elementIndex,
    trackingCondition: isInView && !!recommendations && recommendations.length > 0,
    sourceCategoryId,
  });

  if ((error && !recommendations) || (recommendations && recommendations.length === 0)) {
    return null;
  }

  return (
    <div ref={recoEl}>
      {!recommendations ? (
        <RecoCarouselSkeleton type="BRAND" />
      ) : (
        <RecoCarousel title={title} loadingState={!recommendations}>
          {recommendations.map((recommendation, idx) => (
            <RecoBrandCard
              pageTemplate={pageTemplate}
              // rule is disabled because order doesn't change between rerenders
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              position={idx}
              recommendationData={recommendation}
              brandsLength={recommendations.length}
              rdeType={RDE_TYPE}
            />
          ))}
        </RecoCarousel>
      )}
    </div>
  );
};
