import { Box, Skeleton, Stack } from '@packages/shared';

export const CompactAndColumn = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: ['column', 'column', 'row'],
      gap: [2, 2, 4],
      p: 2,
      width: '100%',
    }}
  >
    <Stack spacing={2} sx={{ flex: '1 1 50%' }}>
      <Skeleton variant="rectangular" width="100%" height={145} />
      <Skeleton variant="rectangular" width="100%" height={40} />
      <Skeleton variant="rectangular" width="100%" height={20} />
    </Stack>
    <Stack spacing={2} sx={{ flex: '1 1 50%' }}>
      {[...Array(5)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton key={index} variant="rectangular" width="100%" height={15} />
      ))}
    </Stack>
  </Box>
);
