import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgEnergyEfficiencyOld = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 35 20" sx={{
    ...sx
  }} {...other}><path fill="currentColor" d="M26.1 0H0v20h26.1L35 9.6z" /></SvgIcon>;
};
const Memo = memo(SvgEnergyEfficiencyOld);
export default Memo;