/* istanbul ignore file */
import type { GtmEventEECproductClick } from '@packages/tracking/src/hooks/useTracking/types';

export const prepareEECproductClick = ({
  brand,
  categoryName = '',
  list,
  name,
  pid,
  position,
  priceValue,
  trackingToken,
}: {
  brand: string;
  categoryName?: string;
  list: string;
  name: string;
  pid: string;
  position: number;
  priceValue: number;
  trackingToken?: string;
}): GtmEventEECproductClick => ({
  event: 'EECproductClick',
  ecommerce: {
    click: {
      actionField: {
        list,
        action: 'click',
      },
      products: [
        {
          brand,
          category: categoryName,
          id: pid,
          name,
          position,
          price: priceValue,
          trackingToken,
        },
      ],
    },
  },
});
