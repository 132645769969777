import dynamic from 'next/dynamic';
import { InspiringSearchTeaserWithAbTesting } from '@packages/modules/src/InspiringSearch/InspiringSearchTeaser/InspiringSearchTeaserWithAbTesting';
import type { PlaceholderType } from './types';
import { PlaceholderTypes } from './types';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';

const DynamicComponentContactForm = dynamic(
  () => import(/* webpackChunkName: 'CMS_ContactForm' */ '../Forms/Contact/ContactForm'),
  {
    ssr: false,
  },
);

const DynamicRateProtectionForm = dynamic(
  () =>
    import(
      /* webpackChunkName: 'CMS_RateProtectionForm' */ '../Forms/RateProtection/RateProtectionForm'
    ),
  {
    ssr: false,
  },
);

export const Placeholder = ({ data }: { data: PlaceholderType }) => {
  const { field_placeholder_variants: variant } = data;

  switch (variant) {
    case PlaceholderTypes.ContactForm:
      return <DynamicComponentContactForm />;
    case PlaceholderTypes.RateProtectionType:
      return <DynamicRateProtectionForm />;
    case PlaceholderTypes.SearchBuddy:
      return <InspiringSearchTeaserWithAbTesting />;
    default:
      return <div />;
  }
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.Placeholder]: Placeholder });
