import { useCookieConsent } from '@packages/shared/src/hooks/useCookieConsent/useCookieConsent';
import { useEffect } from 'react';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';

/**
 * This component represents external JavaScript resources to be used on content pages.
 * This component delivers product content generated by HappyContest.
 *
 * @param src String contains happy Contest Source
 * @param id String contains happy Contest Identifier
 * @returns JSX.Element to be placed in PageLayout
 */
export const HappyContest = ({ src, id }: { src: string; id: string }) => {
  const isAllowed: boolean = useCookieConsent('C0004');
  const dispatchGTMEvent = useTracking();

  useEffect(() => {
    if (isAllowed) {
      dispatchGTMEvent({
        event: 'RenderHappyContest',
        happyContestSrc: src,
      });
    }
  }, [dispatchGTMEvent, isAllowed, src]);

  // INSPIRE-3490 - wrap the actual happy contest container into another one to be able to fully unload the script on route change
  return (
    <div key="happy-contest-script">
      <div id={id} />
    </div>
  );
};
