import { Box, Carousel } from '@packages/shared';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { defineMessage, useIntl } from 'react-intl';
import type { CarouselType } from './types';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import type { ParagraphType } from '../../../interfaces';
import { ComponentTypes } from '../../../interfaces';
import { ComponentRecognizer } from '../ComponentRecognizer';

const ariaLabelPrev = defineMessage({
  id: 'navigation.carousel.prev.aria-label',
  defaultMessage: 'Zurück',
});

const ariaLabelNext = defineMessage({
  id: 'navigation.carousel.next.aria-label',
  defaultMessage: 'Vor',
});

/**
 * Represents the Carousel component used in CMS.
 * This component displays a sequence of items (paragraphs) with various configuration options such as autoplay, loop, and slide visibility.
 *
 * @param {CarouselType} data - The data object containing the carousel configuration and content.
 * @returns A Carousel component with customized settings and content.
 */
export const CmsCarousel = ({
  data: {
    field_autoplay: autoplay = false,
    field_infinite: infinite = false,
    field_speed: duration,
    field_margin: styleMargin = '0 0 2rem 0',
    field_padding: stylePadding = '0',
    field_slides_to_show: slidesToShow = 1,
    paragraph,
  },
}: {
  data: CarouselType;
}) => {
  const { isMobile } = useDeviceType();
  const { formatMessage } = useIntl();

  return (
    <Box
      sx={{
        margin: styleMargin,
        padding: stylePadding,
        position: 'relative',
        '& button': {
          position: 'absolute',
        },
      }}
    >
      <Carousel
        aria={{
          buttonPrev: formatMessage(ariaLabelPrev),
          buttonNext: formatMessage(ariaLabelNext),
        }}
        arrowsInsideBoundary
        autoplay={autoplay}
        loop={infinite}
        visibleSlides={slidesToShow}
        showArrows={!isMobile}
        {...(duration && { emblaOptions: { duration } })}
      >
        {paragraph &&
          paragraph.map((item: ParagraphType, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <ComponentRecognizer key={`cms-carousel-item-${index}`} item={item} />
          ))}
      </Carousel>
    </Box>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({
  [ComponentTypes.Carousel]: CmsCarousel,
});
