import { useCookieConsent } from '@packages/shared/src/hooks/useCookieConsent/useCookieConsent';
import { useEffect } from 'react';
import config from '@packages/config';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';

type KuecheCoProp = {
  journey: string;
};

export const KuecheCo = ({ journey }: KuecheCoProp) => {
  // TODO: getting rid of hardcoded key - in storybook I currently cannot get the config of the selected tenant
  const key =
    config.externalScript?.kuecheCo?.key ||
    'pAYrueynYRcqna7lGLoVT39kF83Wy3PYENp81Yw0QJqeTyzAjXvc1FwGOzCm';

  const isAllowedKuecheCo: boolean = useCookieConsent('C0004');
  const dispatchGTMEvent = useTracking();

  useEffect(() => {
    if (isAllowedKuecheCo) {
      dispatchGTMEvent({
        event: 'RenderKuecheCo',
      });
    }
  }, [dispatchGTMEvent, isAllowedKuecheCo]);

  // use a state here to allow clientside changes of consent take effect on toggle the right container
  return (
    <div
      id="conversional-journey"
      data-api="https://conversional.de/api/"
      data-journey={journey}
      data-version="3"
      data-key={key}
    />
  );
};
