import { useCookieConsent } from '@packages/shared/src/hooks/useCookieConsent/useCookieConsent';
import { useEffect } from 'react';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';

export const SmartAssistant = ({ advisorCode }: { advisorCode: string }) => {
  const isAllowed: boolean = useCookieConsent('C0002');
  const dispatchGTMEvent = useTracking();

  useEffect(() => {
    if (isAllowed) {
      dispatchGTMEvent({
        event: 'renderSmartAssistantScript',
        eventValue: {
          smartAssistantAdvisorCode: advisorCode,
        },
      });
    }
  }, [advisorCode, dispatchGTMEvent, isAllowed]);

  // use a state here to allow clientside changes of consent take effect on toggle the right container
  return <div id="advisor-container" />;
};
