import type { FC } from 'react';
import { useRef, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import type { ColorButtonProps } from '../ColorButton';
import { ColorButton } from '../ColorButton';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

const messages = defineMessages({
  colorsHidden: {
    id: 'product-colors.colors-hidden-label',
    defaultMessage: '+{colorsHidden} {colorsHidden, plural, one {Farbe} other {Farben}}',
  },
});

export type ProductColorsProps = {
  /** product colors */
  colors: ColorButtonProps[];
  /** small color buttons and truncate */
  minimal?: boolean;
  /** rounded color buttons */
  rounded?: boolean;
  /** how much color button are shown */
  colorsVisible?: number;
  /** on color change handler function
   * @param index - index of the changed color
   */
  onColorChange?: (index: number) => void;
  onMoreLabelClick?: () => void;
};

/**
 * ProductColors component
 * */
export const ProductColors: FC<ProductColorsProps> = ({
  colors,
  minimal = true,
  rounded = true,
  colorsVisible = 3,
  onColorChange,
  onMoreLabelClick,
}) => {
  const initialActiveColor = colors.find((item) => item.active);
  const [activeColor, setActiveColor] = useState(initialActiveColor?.name);
  const prevActiveColors = useRef(colors);

  if (colors !== prevActiveColors.current) {
    prevActiveColors.current = colors;
    setActiveColor(initialActiveColor?.name);
  }
  const colorsMap = minimal ? colors.slice(0, colorsVisible) : colors;
  const colorsHidden = colors.length - colorsMap.length;

  const handleColorChange = (name: ColorButtonProps['name'], index: number) => {
    setActiveColor(name);

    if (onColorChange) {
      onColorChange(index);
    }
  };

  return (
    <Stack direction="row" gap={minimal ? 1 : 1.5} alignItems="center" role="radiogroup">
      {colorsMap.map((colorProps, index) => (
        <ColorButton
          aria-atomic="true"
          role="radio"
          aria-checked={activeColor === colorProps.name}
          {...colorProps}
          minimal={minimal}
          rounded={rounded}
          active={activeColor === colorProps.name}
          onClick={() => {
            handleColorChange(colorProps.name, index);
          }}
          key={colorProps.name}
        />
      ))}
      {minimal && colorsHidden > 0 && colorsVisible > 0 && (
        <Typography variant="body3" sx={{ ml: 1, cursor: 'pointer' }} onClick={onMoreLabelClick}>
          <FormattedMessage {...messages.colorsHidden} values={{ colorsHidden }} />
        </Typography>
      )}
    </Stack>
  );
};
