export const sizeStyle = {
  base: { width: '7.625rem', height: '7.625rem' },
  lg: {
    width: '15.25rem',
    height: '15.25rem',
  },
};

export const transformStyle = (styleScalingFactor: number = 1, vertical?: boolean) => {
  const verticalTransform = vertical ? 'translateX(0) translateY(-50%)' : '';
  return {
    base: { transform: `scale(calc(${styleScalingFactor} + 0.35)) ${verticalTransform}` },
    lg: {
      transform: `${styleScalingFactor === 1 ? verticalTransform || 'none' : `scale(${styleScalingFactor}) ${verticalTransform}`}`,
    },
  };
};
