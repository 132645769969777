import useSWR from 'swr';
import { useConfig } from '../useConfig/useConfig';
import { useSession } from '../useSession/useSession';

export const useCustomerApi = () => {
  const { jwtToken } = useSession();
  const config = useConfig();

  const { data: customerApi } = useSWR(
    jwtToken ? ['customerApi', jwtToken] : null,
    async ([, token]: Array<string>) => {
      try {
        const [mybuySession, mybuyCustomer] = await Promise.all([
          import('@empiriecom/mybuy-session'),
          import('@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1'),
        ]);
        const { apiKeyManager, FixFeatureUrlMiddleware } = mybuySession;
        const { CustomerApi, Configuration } = mybuyCustomer;

        return new CustomerApi(
          new Configuration({
            fetchApi: (input, init) => fetch(input, init),
            headers: { accept: 'application/json' },
            apiKey: token,
            middleware: [
              apiKeyManager.sessionInvalidationMiddleware,
              new FixFeatureUrlMiddleware('feature-backend-customer'),
            ],
            basePath: config.apiEndpoints.customer,
          }),
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        return null;
      }
    },
  );

  return customerApi || null;
};
