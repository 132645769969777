import { useCookieConsent } from '@packages/shared/src/hooks/useCookieConsent/useCookieConsent';
import { useEffect } from 'react';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';

/**
 * This component represents external JavaScript resources to be used on content pages.
 * This component delivers movex media library content.
 *
 * @param mediaLibraryId String contains media library identifier
 * @returns JSX.Element to be placed in PageLayout
 */
export const Movex = ({ mediaLibraryId }: { mediaLibraryId: string }) => {
  const isAllowed: boolean = useCookieConsent('C0004');
  const dispatchGTMEvent = useTracking();

  useEffect(() => {
    if (isAllowed) {
      dispatchGTMEvent({
        event: 'renderMovexScript',
        mediaLibraryId,
      });
    }
  }, [dispatchGTMEvent, isAllowed, mediaLibraryId]);

  return <div id="LS-media-library-container" />;
};
