import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  voucherValue: { id: 'voucher.value', defaultMessage: 'Gutschein Wert:' },
  voucherValidUntil: { id: 'voucher.valid.until', defaultMessage: 'Gutschein gültig bis:' },
  voucherMinOrderValue: {
    id: 'voucher.min.order.value',
    defaultMessage: 'Mindesteinkaufswert nach Retoure:',
  },
  voucherCustomerGroup: { id: 'voucher.customer.group', defaultMessage: 'Gutschein gültig für:' },
  voucherActivate: { id: 'voucher.activate', defaultMessage: 'Gutschein einlösen' },
  voucherMoreInformation: { id: 'voucher.more.information', defaultMessage: 'Mehr Details' },
  noDataForActive: {
    id: 'voucher.nodata.active',
    defaultMessage: 'Sorry, no content available for active coupons.',
  },
  noDataForExpired: {
    id: 'voucher.nodata.expired',
    defaultMessage: 'Sorry, no content available for expired coupons.',
  },
  errorLoading: {
    id: 'voucher.error.failed.to.load',
    defaultMessage: 'Sorry, no content could be loaded. Please try again later',
  },
});
