import { Stack, Skeleton, Box } from '@packages/shared';

export const RecoProductCardSkeleton = () => (
  <Stack direction="column" spacing={2} width="100%">
    <Skeleton width="100%" height={200} variant="rectangular" />
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Skeleton variant="rectangular" width="100%" height={15} />
      <Skeleton variant="rectangular" width="100%" height={15} sx={{ mt: 1 }} />
      <Skeleton variant="rectangular" width="100%" height={15} />
      <Skeleton variant="rectangular" width="100%" height={15} sx={{ mt: 1 }} />
    </Box>
  </Stack>
);
