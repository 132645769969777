import { Skeleton, Stack } from '@packages/shared';

export const Collapsed = () => (
  <Stack spacing={2} p={2}>
    <Skeleton variant="rectangular" width="100%" height={145} />
    <Skeleton variant="rectangular" width="100%" height={20} />
    <Skeleton variant="rectangular" width="100%" height={20} />
    <Skeleton variant="rectangular" width="100%" height={20} />
  </Stack>
);
